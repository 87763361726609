import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuLabel
} from '../ui/dropdown-menu';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../ui/table';
import { Badge } from '../ui/badge';
import { ChevronDown, ChevronUp, EllipsisVertical, X } from 'lucide-react';
import { Input } from '../ui/input';
import { DownloadIcon, FilterIcon, InviteIcon, SortIcon } from '../../assets/icons';
import { searchIcon } from '../../assets/icons2/index';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter
} from '../ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { useNavigate } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import withUser from '../../redux/Hoc/withUser';
import {
  deleteArchitectureTest,
  deleteLoadTest,
  deleteSecurityTest,
  generateSecurityCertificate,
  getLoadTestResults,
  getSecurityTestDetails,
  getSecurityTestResults
} from '../../utils/util';
import { Checkbox } from '../ui/checkbox';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Calendar } from '../ui/calendar';
import { useToast } from '../ui/use-toast';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import withLoader from '../../redux/Hoc/withLoader';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LoadResultPdf from '../PDF/LoadResultPdf';
import downloadCertificate from '../PDF/DownloadCertificate';
import CircularWithValueLabel from '../ui/circularProgressWithValueLabel';
import { deepClone } from '../../utils/helper';

// import { debounce } from 'lodash';

const DashTable = ({count=10,home, page,setPage,subColumns=[],totalPage,totalPage1, updatedData,tabsConfig, columns,loadColumns,securityColumns, data, buttonType, buttonOptions, user,setLoader,onRowClick=()=>{} }) => {
  const [activeTab, setActiveTab] = useState(tabsConfig[0].value);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data ? data : {});
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [isTerminateDialogOpen, setIsTerminateDialogOpen] = useState(false);
  const [userToTerminate, setUserToTerminate] = useState(null);
  const [removalReason, setRemovalReason] = useState('');
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    testDetails: '',
    testType: '',
    status: [],
    duration: '',
    date: null
  });
  const { toast } = useToast();
  // const [page,setPage] = useState(1);
  const getUniqueValues = (columnId) => {
    return [
      ...new Set(
        Object.values(data)
          .flatMap((tabData) =>
            Array.isArray(tabData) ? tabData.map((item) => item[columnId]) : []
          )
          .filter((value) => value !== undefined && value !== null)
      )
    ];
  };

  getUniqueValues('status').map((status) => (
    console.log("helllllllllllllllllllllllll000000000000111111",status)
  ))
console.log("from dashboard",data)
  console.log(filteredData);

  if (filteredData === null) {
    return <div className="text-center text-gray-500">No data available</div>;
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(()=>{
console.log("load data ",data)
  },[])

  const handleNavigate = (id, name, status) => {
    console.log(id, name);
    if (activeTab === 'architecture') {
      const nameParams = encodeURIComponent(name);
      navigate(`/architecture/info/${id}/${name}`);
    } else if (activeTab === 'security') {
      if (status.toLowerCase() !== 'success') {
        toast({
          variant: 'destructive',
          title: 'Please wait',
          description: 'Test is in progress'
        });
      } else {
        const nameParams = encodeURIComponent(name);
        navigate(`/security/result/${id}/${nameParams}`);
      }
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addEmail();
    } else if (e.key === 'Backspace' && inputValue === '' && emails.length > 0) {
      removeEmail(emails.length - 1);
    }
  };

  const addEmail = () => {
    const trimmedInput = inputValue.trim().replace(/,+$/, '');
    if (trimmedInput && !emails.includes(trimmedInput)) {
      setEmails([...emails, trimmedInput]);
      setInputValue('');
    }
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      // case 'started':
      //   return 'border-[#9747FF] bg-[#9747FF] text-[#9747FF] bg-opacity-25';
      case 'started':
        return 'border-[#FEB101] bg-[#FEB101] text-[#FEB101] bg-opacity-25';
      case 'success':
      case 'passed':
      case 'completed':
        return 'border-[#22C55E] bg-[#22C55E] text-[#22C55E] bg-opacity-25';
      case 'not started':
        return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
      case 'paused':
      case 'pending':
      case 'in progress':
        return 'border-[#FEB101] bg-[#FEB101] text-[#FEB101] bg-opacity-25';
      case 'failure':
        return 'border-[#EF4444] bg-[#EF4444] text-[#EF4444] bg-opacity-25';
      default:
        return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
    }
  };

  const getStatusColorForDot = (status) => {
    switch (status?.toLowerCase()) {
      // case 'started':
      //   return 'bg-[#9747FF]';
      case 'started':
        return 'bg-[#FEB101]';
      case 'success':
      case 'passed':
      case 'completed':
        return 'bg-[#22C55E]';
      case 'not started':
        return 'bg-[#A9A9A9]';
      case 'paused':
      case 'pending':
      case 'in progress':
        return 'bg-[#FEB101]';
      case 'failure':
        return 'bg-[#EF4444]';
      default:
        return 'bg-[#A9A9A9]';
    }
  };

  const getActiveData = () => {
    return filteredData[activeTab] || [];
  };

  const onFilterChange = (newFilteredData) => {
    setFilteredData(newFilteredData);
  };

  const filteredAndSortedData = useMemo(() => {
    let result = getActiveData();

    if (searchTerm) {
      result = result.filter((item) =>
        Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    return result;
  }, [activeTab, searchTerm, filteredData]);


  const fetchData = async () => {
     

    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        page_number: page,
        per_page: 10,
        object_id: user.object_id,
        subscription_id: user.activated_subscription_id
      };
      const response = await getSecurityTestResults(payload);

      setLoader(false);
      console.log("count:",response?.data.count)
      
    
    } catch (err) {
      console.log(err);
    }
  };

  

  const handleDelete = async (id) => {
    setLoader(true)
    const data = {
      user_id: user.user_id,
      enterprise_id: user.enterprise_id ? user.enterprise_id : null,
      id: id,
      object_id: user.object_id
    };
    if (activeTab === 'security') {
      try {
        const response = await deleteSecurityTest(data);
        console.log("delete th data",response.data);

        //after delete the data ,calling the getsecuritytestresults api for update the data in table 

        const payload = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          page_number: page, // defining page const [page] = useState(1);
          per_page: 10,
          object_id: user.object_id,
          subscription_id: user.activated_subscription_id
        };
        const response1 = await getSecurityTestResults(payload);

        console.log("after delete the data updated data",response1.data.data)
        //updatedData comes from parent component through props set the updated data after delete the data
        updatedData(response1.data.data)
        setInterval(() => {
         setLoader(false) 
        }, 1000);
        
      } catch (error) {
        console.log(error);
      }
    } else if (activeTab === 'architecture') {
      try {
        const response = await deleteArchitectureTest(data);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await deleteLoadTest(data);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDownload = async (id,status,name) => {
    if (activeTab === 'security') {
      console.log(id);


      if (status !== 'success') {
        return toast({
          variant: 'destructive',
          title: 'Please wait',
          description: 'Test is not successfully completed'
        });
      }

      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        object_id: user.object_id,
        test_name: name,
        test_id: id,
        subscription_id: user.activated_subscription_id
      };
      const response = await getSecurityTestDetails(payload);
      console.log("risks :",response)



      const filteredAlerts = response?.data.data.security_alert_data
      ?.filter((alert) => alert.risk !== 'Informational' )
      console.log("risks :",filteredAlerts.length)


      if(filteredAlerts && filteredAlerts?.length>0){
        return toast({
          variant: 'destructive',
          title: 'Please wait',
          description: 'Not passed all the test'
        });
      }
      try {
        const payload = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          test_id: [id],
          object_id: user.object_id
        };
        const generateCertificateNumber=()=> {
          const randomNum = Math.floor(1000 + Math.random() * 9000); // Random 4-digit number
          const timestamp = Date.now(); // Timestamp to make it unique
          return `CERT-${randomNum}-${timestamp}`;
      }
      const certificateNo=generateCertificateNumber()
        if (response) {
          // Download the certificate with the specified details
          downloadCertificate({
              websiteUrl: response.data.data.url,
              certificateNumber: certificateNo,
              issueDate: moment().format('DD/MM/YYYY'),
          });
      } else {
          console.error('Response is undefined or null.');
      }
     
        // const response = await generateSecurityCertificate(payload);
        // console.log(response);
       
       
        // Assuming the response contains a list of items with URLs
        // for (const item of response.data.data) {
        //   const pdfResponse = await fetch(item.url);
        //   const pdfBlob = await pdfResponse.blob();

        //   const url = window.URL.createObjectURL(pdfBlob);
        //   const link = document.createElement('a');
        //   link.href = url;
        //   link.download = `${item.test_id}.pdf`; // Optional: set the filename for the download
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);

        //   window.URL.revokeObjectURL(url); // Clean up the URL object
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDownloadAll = async () => {
    if (activeTab === 'security') {
      try {
        const test_id = data?.security.map((item) => item.test_id);

        const payload = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : '',
          test_id: test_id,
          object_id: user.object_id
        };

        const response = await generateSecurityCertificate(payload);
        console.log(response);
        const items = response.data.data;
        const zip = new JSZip();
        const folder = zip.folder('pdfs');

        for (const item of items) {
          const pdfResponse = await fetch(item.url);
          const pdfBlob = await pdfResponse.blob();
          folder.file(`${item.test_id}.pdf`, pdfBlob);
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'SecurityTestCertificates.zip');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const confirmTermination = () => {
    console.log(`User ${userToTerminate.testDetails} has been removed. Reason: ${removalReason}`);
    setIsTerminateDialogOpen(false);
    setUserToTerminate(null);
    setRemovalReason('');
    // Implement user removal logic here
  };

  const closeTerminateDialog = () => {
    setIsTerminateDialogOpen(false);
    setUserToTerminate(null);
    setRemovalReason('');
  };


  //handle sort for latest
  const handleSortByLatest = async () => {
    setLoader(true)
    const payload = {
      // sort_by: "created_at",
      sort_order: "latest",
      enterprise_id: user.enterprise_id ? user.enterprise_id : null,
      user_id: user.user_id,
      object_id: user.object_id,
      page_number: currentPage,
      per_page:10,
    };
  
    const response = await getSecurityTestResults(payload);
  
    // Assuming response.data.data is the sorted array
    if(activeTab==="security")
    {
       setFilteredData((prevData) => ({
      ...prevData,
      [activeTab]: response.data.data, // Set the data under the active tab
    }));
    }
    else{
      
      const response = await getLoadTestResults(payload);
      setFilteredData((prevData) => ({
        ...prevData,
        [activeTab]: response.data.data, // Set the data under the active tab
      }));
      console.log("Sorted array:", response.data.data);
    }
   
  setLoader(false)
    console.log("Sorted array:", response.data.data);
  };
  


   //handle sort for oldest



   const handleSortByOldest = async () => {
    setLoader(true)
    const payload = {
      // sort_by: "created_at",
      sort_order: "oldest",
      enterprise_id: user.enterprise_id ? user.enterprise_id : null,
      user_id: user.user_id,
      object_id: user.object_id,
      page_number: currentPage,
      per_page: 10,
    };
  
    
    
    // Assuming response.data.data is the sorted array
    if(activeTab==="security"){
const response = await getSecurityTestResults(payload);
      setFilteredData((prevData) => ({
        ...prevData,
        [activeTab]: response.data.data, // Set the data under the active tab
      }));
      console.log("Sorted array:", response.data.data);
    }
    else{
      const response = await getLoadTestResults(payload);
      setFilteredData((prevData) => ({
        ...prevData,
        [activeTab]: response.data.data, // Set the data under the active tab
      }));
      console.log("Sorted array:", response.data.data);
    }

    setLoader(false)
  
    
  };
  

  //create map for sort button

  const sortButton=[
    {
      buttonName:"Latest",
      handleButton:handleSortByLatest
    },
    {
      buttonName:"Oldest",
      handleButton:handleSortByOldest
    }
  ]

  const [shouldDownload, setShouldDownload] = useState(false);
  // const handleTestDetails = async () => {
  //   // Fetch your test details here
  
    
  //   // After fetching test details, set the state to trigger the download
  //   console.log("setshould download",reportData,shouldDownload)
  //   if (reportData) {
  //     setShouldDownload(true);
  //     console.log("setshould download 111",reportData,shouldDownload)
  //   }
  // };

  // useEffect(() => {
  //   console.log("setshould download 11234232341",reportData,shouldDownload)
  //   // if (shouldDownload) {
  //   //   // Automatically trigger the download
  //   //   setShouldDownload(false); // Reset state
  //   // }
  // }, [shouldDownload]);


  // useEffect(()=>{
  //   setInterval(() => {
  //     console.log("Hello from  useinrterval bnaveen")
      
  //   }, 5000);
  // })

const [subLoadTestDataRestApi,setSubLoadTestDataRestApi]=useState([])
  useEffect(()=>{
    if(Array.isArray(data?.load) && data?.load?.length>0)
   { const formatData=data?.load?.map(item=>({
      ...item,showDetails:false
    }))
    setSubLoadTestDataRestApi(formatData)
    console.log("data formatdata",data,formatData)
  }
  },[data])

const [toggleUp,settoggleUp]=useState(false)
  const toggleRow = (index) => {
    // settoggleUp(!toggleUp)
		// console.log("Toggle Row ",index)
    // const arr = deepClone(subLoadTestDataRestApi);
    
		// arr[index].showDetails = !arr[index].showDetails;
    const arr = subLoadTestDataRestApi.map((item, itemIndex) => {
      if (itemIndex === index) {
        // Toggle showDetails for the selected index
        return { ...item, showDetails: !item.showDetails };
      } else {
        // Set showDetails to false for all other items
        return { ...item, showDetails: false };
      }
    });
    
		setSubLoadTestDataRestApi(arr);
	};

  const subLoadTestData = [
    {
      test_case_name: "Login Test",
      status: "Passed",
      timeTakenInMS: 120,
    },
    {
      test_case_name: "Signup Test",
      status: "Failed",
      timeTakenInMS: 250,
    },
    {
      test_case_name: "Checkout Test",
      status: "Passed",
      timeTakenInMS: 300,
    },
  ];
  


  const renderTableContent = (data) => (
    <Table>
      <TableHeader className="bg-[#11141F] rounded-lg">
        <TableRow className="border-b border-[#333333]">
          {columns.map((column) => (
            <TableHead
              key={column.id}
              className={`text-white text-muted-foreground text-base font-medium p-4 ${
                // column.id !== 'testDetails' ? 'text-center' : ''
                column.id === 'testDetails' ? '' : column.id === "type" ? "text-left" : "text-center" // slithly align the "type" text to left
              }`}>
              {column.header}
            </TableHead>
          ))}
          <TableHead className="text-white text-xl font-medium text-muted-foreground p-4"></TableHead>
          <TableHead className="text-white text-xl font-medium text-muted-foreground p-4"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="bg-[#11141F]">
        {Array.isArray(data) && data.map((item, index) => (
         <>
          <TableRow key={index} className="border-b border-[#333333]"  >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                className={column.id === 'testDetails' ? '' : 'text-center'}  
                // className={` ${column.id === 'testDetails' ? '' : column.id === 'type' ? "text-left" : 'text-center'}`}
                onClick={activeTab==="load" && item.type ==="loadtest_rest_api" ? ()=>{}:()=>{onRowClick(item)}}
                >
                {column.id === 'testDetails' ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      if (activeTab === 'architecture') {
                        handleNavigate(item.architecture_id, item.testDetails);
                      } else if (activeTab === 'security') {
                        handleNavigate(item.test_id, item.test_name, item.status);
                      }
                    }}>
                    <div className="text-lg pl-2 font-semibold flex gap-6 text-white"><span>{item.test_name} </span> 
                    {/* <CircularWithValueLabel value={10} /> */}
                      </div>
                    <div className="text-[#9747FF] pl-2 text-xs font-medium">{item.url}</div>
                    
                  </div>
                ) : column.id === 'type' ? (
                  <div className="flex ">
                   <div className="text-md text-white font-medium">
                          {item.type === 'security-website' 
                            ? 'Website Scan' 
                            : item.type === 'security-tcp_scan' 
                            ? 'TCP Port Scan' 
                            : item.type === 'security-api-scan' 
                            ? 'API Scan' 
                            : item.type ==="loadtest-nonrelationalDb" || item.type ==="loadtest-relationalDb"
                            ? "Database"
                            : item.type ==="loadtest_rest_api"
                            ? "Rest API"
                            : item.type ==="loadtest-kafka"
                            ? "Message Broker"
                            : item.type==="loadtest-Website"
                            ? "Website"
                            : item.type ==="loadtest-soapapi"
                            ? "SOAP API"
                            : item.type==="loadtest-graphql"
                            ? "GraphQL"
                            : item.type==="loadtest-Websocket"
                            ?"Websocket"
                            :item.type==="security-docker-scan"
                            ?"Docker Image Scan"
                            :item.type==="security-code-scan"
                            ?"Code Scan"
                            :item.type
                          
                          }
                   </div>
                    
                  </div>
                ): column.id === 'status' ? (
                  <Badge
                    className={`${getStatusColor(item.status)} text-base font-semibold w-32 h-8 inline-flex items-start justify-start`}>
                    <div className="flex flex-row justify-center items-center">
                      <div
                        className={`${getStatusColorForDot(item.status)} text-xs w-2 h-2 rounded-full mr-2 items-start `}
                      />
                      {item.status.toLowerCase()==="sTarted".toLowerCase()?"In Progress":item.status[0].toUpperCase() + item.status.slice(1).toLowerCase()}
                    </div>
                  </Badge>
                ) : column.id === 'startTime' ? (
                  <div className="flex flex-col items-center">
                    <div className="text-xs text-white font-medium">
                      {moment(item.created_at).format('MM/DD/YYYY')}
                    </div>
                    <div className="text-xs font-medium text-muted-foreground">
                      {moment(item.created_at).format('hh:mm:ss A')}
                    </div>
                  </div>
                ) : (
                  <div className="text-xs font-medium text-white">{item[column.id]}</div>
                )}
              </TableCell>
            ))}
            {!home &&

            <>
             <TableCell className="text-center">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <EllipsisVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                

{  buttonOptions.filter(option=>
{
  
  if(item.status.toLowerCase()==="success" )
  {
    return option.name !== 'Restart'
  }
  return option.name
}
 ).map((option, index) => (
  <div key={index}>
    <DropdownMenuItem>
     { item.status.toLowerCase()==="success" ? option.name !== 'Restart' &&
    <Button
        variant="none"
        className="flex items-center justify-start px-2 gap-4 h-6 text-sm w-full "
        onClick={() => {
          // Handle different button actions
          if (activeTab === "security") {
            if (option.name === 'Delete') {
              option.actionSecurityDelete(item.test_id);
            } else if (option.name === 'Download') {
              handleDownload(item.test_id, item.status, item.test_name);
            }
            else if(option.name==="Restart"){
              option.actionSecurityRestartTest(item);
            }
          } else if (activeTab === "load") {
            if (option.name === "Download Certificate") {
              option.actionLoadDownloadCertificate(item);
            } else if (option.name === "Delete") {
              option.actionLoadDelete(item);
            } else if (option.name === "Download Reports") {
              option.actionLoadDownloadReports(item);
             
            } 
            else if(option.name==="Restart"){
              option.actionLoadRestartTest(item);
            }
            else {
              option.actionViewLoadResults(item);
            }
          }

          // Automatically download PDF after test details are fetched
          
        }}
      >
        <img
          src={option.icon}
          alt={option.alt}
          className={`h-${option.iconHeight} w-${option.iconWidth} ${option.iconColor}`}
        />
        <span>{option.text}</span>
      </Button>
      :
       <Button
        variant="none"
        className="flex items-center justify-start px-2 gap-4 h-6 text-sm w-full "
        onClick={() => {
          // Handle different button actions
          if (activeTab === "security") {
            if (option.name === 'Delete') {
              option.actionSecurityDelete(item.test_id);
            } else if (option.name === 'Download') {
              handleDownload(item.test_id, item.status, item.test_name);
            }
            else if(option.name==="Restart"){
              option.actionSecurityRestartTest(item);
            }
          } else if (activeTab === "load") {
            if (option.name === "Download Certificate") {
              option.actionLoadDownloadCertificate(item);
            } else if (option.name === "Delete") {
              option.actionLoadDelete(item);
            } else if (option.name === "Download Reports") {
              option.actionLoadDownloadReports(item);
             
            }
            
            else if(option.name==="Restart"){
              option.actionLoadRestartTest(item);
            }
            else {
              option.actionViewLoadResults(item);
            }
          }

          // Automatically download PDF after test details are fetched
          
        }}
      >
        <img
          src={option.icon}
          alt={option.alt}
          className={`h-${option.iconHeight} w-${option.iconWidth} ${option.iconColor}`}
        />
        <span>{option.text}</span>
      </Button>
      }
    </DropdownMenuItem>

 
   
  </div>
))}

                </DropdownMenuContent>
              </DropdownMenu>
            </TableCell>
           {item.type ==="loadtest_rest_api" ? <TableCell onClick={() => toggleRow(index)}>
												{subLoadTestDataRestApi[index]?.showDetails ? (
													<ChevronUp className="inline w-4 h-4" strokeWidth={1.5} />
												) : (
													<ChevronDown className="inline w-4 h-4" strokeWidth={1.5} />
												)}
											</TableCell>
                      :
                      <TableCell >
											
											</TableCell>
                      }
                    
            </>
            
            
            }
          </TableRow>
            {item.type ==="loadtest_rest_api" && subLoadTestDataRestApi[index]?.showDetails && subLoadTestData?.length>0 && (
              <TableRow className=" hover:bg-transparent" >
                <TableCell colSpan="8" className="">
                  <Table className="w-11/12 mx-auto border rounded-md my-7 divide-y divide-gray-600">
                    <TableHeader className="bg-[#11141F] rounded-lg text-sm">
                      <TableRow className="hover:bg-none border-b border-[#333333]">
                        {subColumns.map((column) => (
                          <TableHead
                            key={column.id}
                            className={`text-xs text-white text-muted-foreground text-base font-medium p-4 ${column.id === "test_case_name" ? "text-left text-xs" : "text-center text-xs"
                              }`}>
                            {column.header}
                          </TableHead>
                        ))}
                      </TableRow>
                      {/* Horizontal Line Below Header */}
    {/* <hr className="border-gray-600 w-full mx-auto" /> */}
                    </TableHeader>
                    <TableBody>
                      { Array.isArray(subLoadTestDataRestApi[index]?.data)  && subLoadTestDataRestApi[index]?.data?.length>0 &&
                       
                       subLoadTestDataRestApi[index]?.data.map((subRowData, subIndex) => (
                          <TableRow
                            key={index + "-" + subIndex}
                            className={` border-[#333333]`}
                            
                            >
                            {subColumns.map((column) => (
                              <TableCell
                                key={column.id}
                                onClick={()=>onRowClick({test_id:subRowData?.test_id,test_name:item?.test_name,status:subRowData?.status})}
                                className={` ${column.id === "type" ? "text-left" : "text-center"} cursor-pointer`}>
                                {column.id === "testDetails" ? (
                                  <div className="cursor-pointer" onClick={() => { }}>
                                    <div className="pl-2 text-lg font-semibold text-white">{subRowData.type}</div>
                                    <div className="text-[#9747FF] pl-2 text-xs font-medium">{subRowData.url}</div>
                                  </div>
                                ) : column.id === "type" ? (
                                  <div className="flex items-center justify-center ">
                                    <div className="font-medium text-white text-md">{subRowData.type ==="loadtest_rest_api" ? "Rest Api" : subRowData.type}</div>
                                  </div>
                                ) : column.id === "status" ? (
                                  <Badge
                                    className={`${getStatusColor(subRowData.status)} text-sm font-semibold w-32 h-7 inline-flex items-start justify-start`}>
                                    <div className="flex flex-row items-center justify-center">
                                      <div
                                        className={`${getStatusColorForDot(subRowData.status)} text-xs w-2 h-2 rounded-full mr-2 items-start `}
                                      />
                                      {subRowData.status.toLowerCase() === "started".toLowerCase()
                                        ? "in progress"
                                        : subRowData.status}
                                    </div>
                                  </Badge>
                                ) : column.id === "created_at" ? (
                                  <div className="">
                                    <div className="flex flex-col items-center">
                                      <div className="text-xs font-medium text-white">
                                        {moment(subRowData.created_at).format("MM/DD/YYYY")}
                                      </div>
                                      <div className="text-xs font-medium text-muted-foreground">
                                        {moment(subRowData.created_at).format("hh:mm:ss A")}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="text-xs font-medium text-white">{subRowData[column.id]}</div>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            )}
         </>
        ))}
      </TableBody>
    </Table>
  );

  const renderInviteDialog = () => (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="h-9 w-40 text-sm bg-[#9747FF] hover:bg-[#9747FF] hover:text-[#FFFFFF] flex items-center justify-between">
          <InviteIcon className="h-4 w-4" />
          <span className="sr-only sm:not-sr-only manrope-fontCss font-medium text-sm">
            Invite Members
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[75%] bg-[#222938] text-white">
        <DialogHeader>
          <DialogTitle className="text-xl text-white font-semibold manrope-fontCss">
            Invite people to this workspace
          </DialogTitle>
          <div className="text-[#A9A9A9] text-base font-normal">
            Invite your teammates and access all features together
          </div>
        </DialogHeader>
        <div className="grid gap-4 py-6">
          <div className="flex flex-col gap-2 justify-start">
            <label htmlFor="email" className="text-sm text-muted-foreground">
              Name, Email, or Group Name
            </label>
            <div
              className="relative bg-[#161A27] border border-[#747474] rounded-md p-2 min-h-[112px] flex flex-wrap items-start content-start cursor-text"
              onClick={focusInput}>
              {emails.map((email, index) => (
                <div
                  key={index}
                  className="inline-flex items-center bg-[#2C2C2C] rounded m-1 px-2 py-1">
                  <span className="text-sm">{email}</span>
                  <button
                    onClick={() => removeEmail(index)}
                    className="ml-2 text-gray-400 hover:text-white">
                    <X size={16} />
                  </button>
                </div>
              ))}
              <Input
                ref={inputRef}
                id="email"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                className="flex-grow bg-transparent text-white border-none p-0 m-1 placeholder:text-white placeholder:opacity-50 focus:outline-none"
                placeholder={
                  emails.length === 0 ? 'Type or paste email addresses and press Enter' : ''
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-muted-foreground">{emails.length} emails selected</span>
          <Button className="w-56 h-10 text-white bg-[#9747FF] hover:bg-[#8A3DE0]">
            Send Invite
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

  const renderTerminateDialog = () => (
    <Dialog open={isTerminateDialogOpen} onOpenChange={closeTerminateDialog}>
      <DialogContent className="bg-[#222938] text-white sm:w-1/3 sm:h-64">
        <DialogHeader>
          <DialogTitle className="text-xl text-white font-bold">
            Remove {userToTerminate?.testDetails} from users list?
          </DialogTitle>
        </DialogHeader>
        <p className="text-base text-[#A9A9A9]">
          Are you sure to remove {userToTerminate?.testDetails} from your group?
        </p>
        <div className="py-4">
          <div className="mt-4">
            <label
              htmlFor="removalReason"
              className="block text-base font-medium text-gray-400 mb-2">
              Reason for Removal (Optional)
            </label>
            <Select onValueChange={setRemovalReason} value={removalReason}>
              <SelectTrigger className="w-full bg-[#161A27] border-gray-600 text-white">
                <SelectValue placeholder="Choose reason from the list" />
              </SelectTrigger>
              <SelectContent className="bg-[#222938] text-white">
                <SelectItem value="inactive">Inactive user</SelectItem>
                <SelectItem value="left_company">Left the company</SelectItem>
                <SelectItem value="security_concern">Security concern</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter className="justify-end mt-4">
          <Button
            type="button"
            variant="secondary"
            onClick={closeTerminateDialog}
            className="w-full sm:w-[40%] bg-transparent border border-gray-600 hover:bg-gray-700">
            Go Back
          </Button>
          <Button
            type="button"
            onClick={confirmTermination}
            className="w-full sm:w-[40%] bg-purple-600 hover:bg-purple-700 text-white">
            Remove
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

 
  


  
  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage); // Update page state
    console.log("PAGE NUMBER",newPage)
    setPage(newPage)
  setLoader(true)
    try
     {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id || null,
        page_number: newPage, // Ensure page number is sent in the API request
        per_page: itemsPerPage,
        object_id: user.object_id,
        subscription_id: user.activated_subscription_id,
      };
  
      if(activeTab==="security"){


        const response = await getSecurityTestResults(payload);
        setLoader(false);
    
        // Ensure you're updating `filteredAndSortedData` correctly with the new data
        if (response?.data?.data) {
          setFilteredData({ security: response.data.data });
          console.log("after paginated the filterde data ",filteredData)
        }
    
        // Optional: Log to confirm data for the new page
        console.log("Data for Page", newPage, response?.data?.data);

      }
      else{
        const response=await  getLoadTestResults(payload)

        setLoader(false);
    
        // Ensure you're updating `filteredAndSortedData` correctly with the new data
        if (response?.data?.data) {
          setFilteredData({ load: response.data.data });
        }
    
        // Optional: Log to confirm data for the new page
        console.log("Data for Page", newPage, response?.data?.data);

      }
    
    } catch (err) {
      console.error(err);
    }
  };
  
  
 
  
  
  const paginatedData = useMemo(() => {
    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;
  
    const startIndex = 0;
    const endIndex = 10

    // Log to check start and end indices
    console.log("Start Index:", startIndex, "End Index:", endIndex);
    
    // Ensure `filteredAndSortedData` is not undefined or null
    if (!filteredAndSortedData || filteredAndSortedData.length === 0) {
      return [];
    }
  console.log("filteredAndSortedData",filteredAndSortedData)
    // Log the sliced data to confirm pagination
    console.log("Paginated Data:", filteredAndSortedData.slice(startIndex, endIndex));
  
    return filteredAndSortedData.slice(startIndex, endIndex);
  }, [currentPage, filteredAndSortedData]);
  
  const totalPages =totalPage===undefined? totalPage1?.[activeTab] ?? 1:totalPage
  
  useEffect(()=>{
    console.log("Total pages ",totalPages)
  },[])

  const applyFilters = (item) => {
    console.log("filters ",filters)
    console.log("items ",item)

    return (
      activeTab === "security" ? (
        (filters?.testDetails === '' ||
          item?.test_name.toLowerCase().includes(filters?.testDetails.toLowerCase())) &&
        (filters.testType === '' || item.testType === filters.testType) &&
        (filters.status.length === 0 || filters.status.includes(item.status)) &&
        (filters.duration === '' || item.duration === filters.duration) &&
        (filters.date === null || moment(item.startTime).isSame(filters.date, 'day'))
      ) : (
        // Add logic for the else case (when activeTab is not "security")
        (filters?.testDetails === '' ||
          item?.test_name.toLowerCase().includes(filters?.testDetails.toLowerCase())) &&
        (filters.testType === '' || item.testType === filters.testType) &&
        (filters.status.length === 0 || filters.status.includes(item.status)) &&
        (filters.duration === '' || item.duration === filters.duration) &&
        (filters.date === null || moment(item.startTime).isSame(filters.date, 'day'))
      )
    );
    
  };
  useMemo(() => {
    console.log("securitydata: ", data);
  
    // if (data.security === 'None' || data.architecture === null) {
    //   return ;
    // }
  
    const newFilteredData = {};
    console.log("at filtering part from me ",filteredData)
    Object.keys(data ? data : {}).forEach((tab) => {
      // Check if data[tab] exists and is an array before filtering
      if (Array.isArray(data[tab])) {
        newFilteredData[tab] =data[tab].filter(applyFilters);
      } else {
        console.warn(`data[${tab}] is not an array, skipping filtering for this tab.`);
      }
    });
  
    setFilteredData(newFilteredData);

  }, [filters, data]);
  
  useEffect(()=>{
console.log("new filtersted data sekar",filteredData)
console.log("data data data : ",data)
  },[filteredData])

  const [openFilter, setOpenFilter] = useState(false);
  const [openSort, setOpenSort] = useState(false);

  const handleFilterChange = (columnId, value) => {
    setFilters((prev) => ({ ...prev, [columnId]: value }));

    
  };


  // const handleFilterChange =(
  //   debounce((columnId, value) => {
  //     setFilters((prev) => ({ ...prev, [columnId]: value }));
  //   }, 300), // 300ms debounce time
  //   []
  // );
  const handleStatusChange = (status, checked) => {
    setFilters((prev) => ({
      ...prev,
      status: checked ? [...prev.status, status] : prev.status.filter((s) => s !== status)
    }));
  };

  const handleDateChange = (date) => {
    setFilters((prev) => ({ ...prev, date }));
  };

  const clearFilters = () => {
    setFilters({ testDetails: '', testType: '', status: [], duration: '', date: null });
  };

  return (
    <div className="p-4 bg-[#161A27] text-white w-full rounded-lg manrope-fontCss">
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full ">
        <div className="flex justify-between items-center mb-4">
          <TabsList className="bg-transparent">
            {tabsConfig.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className="text-white data-[state=active]:text-white">
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
          <div className="flex items-center gap-3">
            {/* <div className="relative flex-1 md:w-[100px] lg:w-[255px] w-full ">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="absolute -left-6  top-1 h-5 w-5 text-muted-foreground"
              />
              <Input
                type="search"
                placeholder="Search here what you are looking for..."
                className="bg-transparent px-0 h-6 border-0 border-b rounded-none border-muted-foreground focus:ring-0 text-sm text-muted-foreground"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div> */}
            {buttonType === 'Download' ? (
              // <Button
              //   variant="ghost"
              //   className="h-9 w-[151px] text-xs bg-[#222938] hover:bg-[#222938] border-[#444444] border-2 hover:text-[#FFFFFF] flex items-center justify-between"
              //   onClick={handleDownloadAll}>
              //   <DownloadIcon className="h-4 w-4" />
              //   <span className="sr-only sm:not-sr-only text-sm font-light">Download All</span>
              // </Button>
              <></>
            ) : buttonType === 'Invite' ? (
              renderInviteDialog()
            ) : null}
           
            {/* Filter Old Component  */}
           
            {/* <FilterComponent
              data={data}
              columns={['Test Details', 'Test Type', 'Status', 'Duration', 'Date']}
              onFilterChange={onFilterChange}
            /> */}


             {/* Filter Component  */}

             <DropdownMenu open={openFilter} onOpenChange={setOpenFilter}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="bg-[#222938] h-9 px-4 gap-2 border border-[#444444]">
          <FilterIcon className="h-4 w-4" />
          <span>More Filters</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[200px] bg-[#222938] text-white"
        onClick={(e) => e.stopPropagation()}>
        <DropdownMenuLabel>Apply Filters</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <Accordion type="single" collapsible className="w-full">
          {columns.map((column) => 
          (
            column.id!=="startTime" && column.id!=='virtual_users' && column.id!=='duration'  && <AccordionItem value={column.id} key={column.id}>
              <AccordionTrigger className="text-base font-medium manrope-fontCss">
                {column.header}
              </AccordionTrigger>
             
              <AccordionContent>
                {column.id === 'status' ? (
                  getUniqueValues('status').map((status) => (
                    <div key={status} className="flex items-center space-x-2 pt-3">
                      <Checkbox
                        id={status}
                        checked={filters.status.includes(status)}
                        onCheckedChange={(checked) => handleStatusChange(status, checked)}
                      />
                      <label
                        htmlFor={status}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}>
                        {status}
                        {console.log("helllllllllllllllllloooooooooo",status)}
                      </label>
                    </div>
                  ))
                )
                //  : column.id === 'date' ? (
                //   <Calendar
                //     mode="single"
                //     selected={filters.date}
                //     onSelect={handleDateChange}
                //     className="rounded-md border"
                //     onClick={(e) => e.stopPropagation()}
                //   />
                // )
                 :(column.id === 'type' || column.id =="testDetails") && (
                  <Input
                    placeholder={`Filter by ${column.header}`}
                    value={filters[column.id]}
                    onChange={(e) => handleFilterChange(column.id, e.target.value)}
                    className="bg-[#222938] border-gray-600"
                    onClick={(e) => e.stopPropagation()}
                  />
                )}
              </AccordionContent>
              <DropdownMenuSeparator />
            </AccordionItem>
          ),console.log(columns,"columnid")
          )}
        </Accordion>
        <DropdownMenuItem className="items-center flex flex-col justify-center" onSelect={(e) => e.preventDefault()}>
          <Button onClick={clearFilters}>Clear Filters</Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
            <div>
              {/* <Button
                variant="ghost"
                size="sm"
                className="h-9 px-4 w-full gap-2 text-sm bg-[#222938] border border-[#444444] flex items-center justify-between">
                <SortIcon className="h-4 w-4" />
                <span className="sr-only sm:not-sr-only text-sm font-light">Sort By</span>
              </Button> */}

              
            
              
              <DropdownMenu open={openSort } onOpenChange={setOpenSort }>
             
             <DropdownMenuTrigger asChild>
             
               <Button
                           // onClick={handleSortByLatest}
                             variant="ghost"
                             size="sm"
                             className="h-9 px-4 w-full gap-2 text-sm bg-[#222938] border border-[#444444] flex items-center justify-between">
                             <SortIcon className="h-4 w-4" />
                             <span className="sr-only sm:not-sr-only text-sm font-light">Sort</span>
                           </Button>
             
              </DropdownMenuTrigger>
             
             <DropdownMenuContent
                     className="w-[150px]  bg-[#222938] text-white"
                     onClick={(e) => e.stopPropagation()}>
             <DropdownMenuItem className="items-center flex flex-col justify-center" onSelect={(e) => e.preventDefault()}>
             {/* <DropdownMenuLabel>Apply Sort</DropdownMenuLabel>
             <DropdownMenuSeparator className="mb-2" /> */}
             
             
                     <div className='flex flex-col gap-4' >
                     
                     {
                       sortButton.map((sort)=>(
                      <>
                         <Button
                           onClick={sort.handleButton}
                             variant="ghost"
                             size="sm"
                             className="h-9 px-4  w-full gap-2 text-md font-bold bg-[#222938] border-2 border-[#444444]  flex items-center justify-between">
                             <SortIcon className="h-4 w-4" />
                             <span className="sr-only sm:not-sr-only text-md font-semibold">{sort.buttonName}</span>
                           </Button>
                      </>
                       ))
                      }
             
                     </div>
                     </DropdownMenuItem>
                   </DropdownMenuContent>
                 </DropdownMenu>
                         
            </div>
          </div>
        </div>
        {tabsConfig.map((tab) => (
  <TabsContent key={tab.value} value={tab.value}>
    {renderTableContent(paginatedData)} {/* Ensure paginatedData is updated */}
  </TabsContent>
))}

      </Tabs>
      <div className="flex justify-end mt-4">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      <div className="manrope-fontCss">{renderTerminateDialog()}</div>
    </div>
  );
};


export default withLoader(withUser(DashTable))

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { useToast } from '../../components/ui/use-toast'; // Assuming useToast is imported from a utility
import { getLoadTestDetail, getAIRecommendationData, uploadTestReport } from '../../utils/util'; // Import API functions
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '../../components/ui/table';
import { Button } from '../../components/ui/button';
import DOMPurify from 'dompurify';
import { Card } from '../../components/ui/card';
import RecommendationIcon from '../../assets/icons/recommendation-icon';
import CloseIcon from '../../assets/icons/close';

/* eslint-disable no-unused-vars */
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import LoadResultPdf from '../../components/PDF/LoadResultPdf';
import { DownloadIcon } from 'lucide-react';
import { marked } from 'marked';
import MDXRenderer from './MDXRenderer';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { CopyIcon, CheckIcon } from '@radix-ui/react-icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReportPublishDropdown from '../../components/reportPublishDropdown';
import { Switch } from '../../components/ui/switch-no-label';
import { addCommentInJira, createIssue, getSolutionForApiAutomation, getSolutionForLoadTest, uploadFileJira } from "../../api/app";
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Loader from '../../components/Loader';

const LoadTestResults = ({ user, setLoader }) => {
  const { test_id,test_name } = useParams();
  const [testDetails, setTestDetails] = useState(null);
  const [recommendationData, setrecommendationData] = useState(null);
  const { toast } = useToast();
  const [isCreateIssue,setISCreateIssue]=useState(false)

  const CopyButton = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    return (
      <button
        onClick={handleCopy}
        className="absolute h-8 w-8 top-2 right-2 flex items-center justify-center text-white bg-gray-600 rounded hover:bg-gray-700 focus:outline-none">
        {copied ? <CheckIcon /> : <CopyIcon />}
      </button>
    );
  };

  const CodeBlock = ({ children, language = 'javascript' }) => (
    <div className="relative rounded-md my-4">
      <SyntaxHighlighter language={language} style={tomorrow} className="rounded-md overflow-auto">
        {String(children).trim()}
      </SyntaxHighlighter>
      <CopyButton text={children} />
    </div>
  );

  const renderContent = (aiData) => {
    return (
      <div className="w-full">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw, rehypeSanitize]}
          components={{
            h1: ({ node, ...props }) => (
              <h1 className="text-2xl font-bold my-4 text-white" {...props} />
            ),
            h2: ({ node, ...props }) => (
              <h2 className="text-xl font-semibold my-4 text-white" {...props} />
            ),
            p: ({ node, ...props }) => <p className="text-base my-4 text-white" {...props} />,
            ul: ({ node, ...props }) => (
              <ul className="list-disc pl-5 my-4 text-white" {...props} />
            ),
            ol: ({ node, ...props }) => (
              <ol className="list-decimal pl-5 my-4 text-white" {...props} />
            ),
            li: ({ node, ...props }) => <li className="my-1 text-white" {...props} />,
            table: ({ node, ...props }) => (
              <table
                className="table-auto w-full my-6 border-collapse border border-gray-600"
                {...props}
              />
            ),
            th: ({ node, ...props }) => (
              <th
                className="font-semibold px-4 py-2 border border-gray-600 bg-gray-700 text-left text-white"
                {...props}
              />
            ),
            td: ({ node, ...props }) => (
              <td className="border px-4 py-2 bg-gray-800 text-white" {...props} />
            ),
            tr: ({ node, ...props }) => <tr className="border border-gray-600" {...props} />,
            blockquote: ({ node, ...props }) => (
              <blockquote
                className="italic pl-4 border-l-4 border-gray-600 my-4 text-white"
                {...props}
              />
            ),
            code: ({ node, inline, className, children, ...props }) => {
              return !inline ? (
                <CodeBlock>{children}</CodeBlock>
              ) : (
                <code className="bg-gray-800 text-white rounded px-2 py-1" {...props}>
                  {children}
                </code>
              );
            }
          }}>
          {aiData}
        </ReactMarkdown>
      </div>
    );
  };

  useEffect(() => {
    const fetchLoadTestDetails = async () => {
      setLoader(true);
      try {
        console.log(user,"user")
        const response = await getLoadTestDetail({
          user_id: user.user_id,
          enterprise_id: user.enterprise_id,
          test_name: test_name,
          test_id:test_id,
          object_id: user.object_id
        });
        let result = response.data;
        if (result.status == 'success') {
          result.data.file_path = [result.data.file_path['0']];
          setTestDetails(result.data);
        } else {
          switch (result.code) {
            case 400:
              toast({ title: 'Error', description: result.message, variant: 'error' });
              break;
            case 404:
              toast({ title: 'Data not found', description: result.message, variant: 'error' });
              break;
            case 500:
              toast({
                title: 'Internal server error',
                description: result.message,
                variant: 'error'
              });
              break;
            default:
              toast({ title: 'Unknown error', description: result.message, variant: 'error' });
          }
        }
      } catch (error) {
        console.error('API call failed:', error);
        toast({
          title: 'Failed to connect to the server',
          description: error.message,
          variant: 'error'
        });
      } finally {
        setLoader(false);
      }
    };
    fetchLoadTestDetails();
  }, [test_name, setLoader, toast]);

  const getAIRecommendation = async () => {
    try {
      if (testDetails.model_Solution != null) {
        setrecommendationData(testDetails.model_Solution);
      } else {
        const payload = {
          user_id: user?.user_id,
          enterprise_id: user?.enterprise_id,
          testname: testDetails?.test_name,
          test_id: testDetails?.test_id,
          subscription_id: user?.activated_subscription_id,
          object_id: user?.object_id,
          type: testDetails?.type
        };
        setLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT}/loadTestSolution`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.access_token}`
            },
            body: JSON.stringify(payload)
          }
        );
        setLoader(false);
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let content = '';
        if (!response.ok) {
          const { value } = await reader.read();
          content += decoder.decode(value, { stream: true });
          content = JSON.parse(content);
          setrecommendationData(content.message);
          throw new Error(content.message);
        }

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          content += decoder.decode(value, { stream: true });
          setrecommendationData(content);
        }
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message
      });
    }
  };

  const toggleRecommendationClose = () => {
    setrecommendationData(null); // Collapse the card when the recommendation is closed
  };

  const renderTestDetailsResults = () => {
    const resultList = testDetails.result.map((each, index) => {
      const otherDetails = Object.entries(each).filter(([key]) => key !== 'metric_name');
      return (
        <li key={index} className="mb-6">
          <h3 className="font-semibold text-xl text-gray-300 mb-2">{each.metric_name}</h3>

          <ul className="bg-card p-4 rounded-lg shadow-lg text-sm">
            {otherDetails.map(([key, value], idx) => (
              <li
                key={idx}
                className="flex justify-between py-1 border-b border-gray-700 last:border-none">
                <span className="font-medium text-gray-400">{key.replace(/_/g, ' ')}:</span>
                <span className="text-gray-200">{value}</span>
              </li>
            ))}
          </ul>
        </li>
      );
    });

    return (
      <div className="flex flex-row mt-4 gap-4 w-full mb-4">
        {/* Test Details Section with Vertical Scroll */}
        <ul
          className={`${recommendationData != null ? 'w-1/2' : 'w-full'} bg-card gap-4 p-4 h-[550px] overflow-y-auto mt-9 justify-start items-center`}>
          {resultList}
        </ul>

        {/* Recommendation Section */}
        {recommendationData != null && (
          <Card className="bg-card w-1/2 justify-end gap-4 p-4 h-[550px] overflow-y-auto mt-9">
            <div className="flex justify-between items-start">
              <div className="flex gap-4 mt-2">
                <RecommendationIcon className="w-2 h-2 text-primary" />
                <span className="sr-only sm:not-sr-only text-sm font-light text-primary text-wrap">
                  {testDetails.model_Solution != null
                    ? 'Model Solution'
                    : 'Generating AI Recommendations...'}
                </span>
              </div>
              <Button variant="ghost" onClick={toggleRecommendationClose}>
                <CloseIcon />
              </Button>
            </div>
            <div>{renderContent(recommendationData)}</div>
          </Card>
        )}
      </div>
    );
  };

  const [isLoading,setIsLoading]=useState(false)
  const [jiraKey,setJiraKey]=useState()
  const [copyMessage,setCopyMessage]=useState()
  const [issueCreatedSuccessfullyDialog,setIssueCreatedSuccessfullyDialog]=useState(false)
  const handleCloseIssueCreatedSuccessfullyDialog = () => {
    setIssueCreatedSuccessfullyDialog(!issueCreatedSuccessfullyDialog)
   
  }
  
   
  const [pdfFile, setPdfFile] = useState(null);

  // Function to generate a direct File object
  const generatePdfFile = async () => {
    try {
      const instance = pdf(<LoadResultPdf data={testDetails} />);
      const blob = await instance.toBlob(); // Generate Blob
      const file = new File([blob], "LoadResultReport.pdf", { type: "application/pdf" });
      // setPdfFile(file);
      console.log("PDF File generated:", file);

      const payload={
        test_id: testDetails?.test_id,
        object_id:user.object_id,
        file:file

      }
      const filePath=await uploadTestReport(payload)
      console.log("response successful:", filePath.data);
      if(filePath.data){
        setPdfFile(filePath.data.data.key)
      }
      return file;
    } catch (error) {
      console.error("Error generating PDF file:", error);
    }
  };



  useEffect(()=>{
    if(testDetails){
      generatePdfFile()
    }
  },[testDetails])

  const handleUploadFileInJira = async(formData) => {
		
	
        try {
		
            if(formData ){
                const response= await uploadFileJira(formData)
                console.log("Repsonse for jira upload file",response)
				
            }
            
        
        } catch (error) {
		
            console.log("Error in jira upload file api",error)
        }
		
	};

	const handleCreateIssue = async () => {
    setIsLoading(true)
    console.log("USER",user)
		// console.log("Result Data", resultData);
	
		// Initialize plain text content for the error report
		let plainTextContent = "";
		const errorsArray = []; // Initialize an array to store errors
	  let comment=""

  if(testDetails?.result[0]?.passed===100){
    setISCreateIssue(false)
    setIsLoading(false)
    
    return
  }
    console.log("Generated Plain Text Content:", plainTextContent);
  
    const payload = {
      issue_name: `${test_name || "Unnamed"}`,
      description: `Total No of virtual users *${testDetails?.number_of_virtual_users}* \nPassed:*${testDetails?.result[0]?.passed}* \nFailed:*${testDetails?.result[0]?.failed}*`,
    };
		try {
			// Call the createIssue API
			const response = await createIssue(payload);
			console.log("Response for handleCreateIssue", response);
	
			if (response.data.data) {
				const payload = {
					jira_key: response?.data?.data?.key,
					// filePath: reportFilePath, // Use dynamic report file path
          filePath:pdfFile && pdfFile
				};
        setJiraKey(response?.data?.data?.key)
				handleUploadFileInJira(payload);
        console.log("Generated Errors Array:", errorsArray)
        const solutionPayload = {
          user_id: user?.user_id,
          enterprise_id: user?.enterprise_id,
          testname: testDetails?.test_name,
          test_id: testDetails?.test_id,
          subscription_id: user?.activated_subscription_id,
          object_id: user?.object_id,
          type: testDetails?.type
        };
    
        const solutionResponse = await getSolutionForLoadTest(solutionPayload);
        console.log(comment)
       
        const commentPayload={
          issue_key:response?.data?.data?.key,
          comment:solutionResponse.data
        }
        const commentResponse =await addCommentInJira(commentPayload)
        console.log("response for add comment in jira",commentResponse)
        if(commentResponse.data.data.body){
          setIssueCreatedSuccessfullyDialog(true)
       setIsLoading(false)
       } 
       } 
			
		} catch (error) {
			console.error("Error in handleCreateIssue", error);
		}
	};

  useEffect(()=>{
    if(isCreateIssue){
      handleCreateIssue()
    }
  },[isCreateIssue])

  return (
    <div className="p-4">
       {isLoading && <Loader />}
      {testDetails ? (
        <div>
          <div className="flex justify-between">
            <h1 className="text-2xl font-bold mb-4">Load Test Details - {testDetails.test_name}</h1>
            <div className='flex w-full  justify-end items-center gap-10 ' >
            {/* {test_name && pdfFile && testDetails?.result[0]?.passed===100 && (
						<div 
						 className="text-white h-10 w-60 text-base rounded-md justify-center  border-2  flex items-center gap-2 font-bold  "
						 >
						<span>Create Issue</span>
						<Switch
                  id="create-issue"
                  className="bg-background "
                  value={isCreateIssue}
                  onCheckedChange={() => setISCreateIssue(!isCreateIssue)}
                />
						</div>
					) } */}
           <Dialog
  open={issueCreatedSuccessfullyDialog}
  onClose={handleCloseIssueCreatedSuccessfullyDialog}
  aria-describedby="alert-dialog-description"
  fullWidth={"sm"}
  maxWidth={"sm"} // Adjust the max width to small (sm)
>
  <DialogTitle
    sx={{
      fontSize: "1.25rem",
      fontWeight: "600",
      backgroundColor: "#161A27",
      color: "white",
    }}
  >
    Created Issue Successfully
  </DialogTitle>
  <DialogContent
    sx={{
      padding: "16px",
      backgroundColor: "#161A27",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }}
  >
    <div className="flex justify-between items-center gap-4">
      <span className="whitespace-nowrap font-medium text-sm text-white">
        JIRA KEY
      </span>
      <div className="flex items-center gap-2 w-full">
        <input
          className="bg-transparent p-1 rounded-md border px-4 border-gray-600 min-h-9 text-white max-h-24 placeholder:text-white flex-grow"
          id="jiraKey"
          value={jiraKey}
          disabled
        />
        <button
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(jiraKey);
            setCopyMessage("Copied Successfully!");
            setTimeout(() => setCopyMessage(""), 2000); // Clear message after 2 seconds
          }}
          className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#9747FF] text-primary-foreground shadow hover:bg-[#9747FF] rounded-md px-4 h-8 text-sm"
        >
          Copy
        </button>
      </div>
    </div>

    {copyMessage && (
      <p className="text-sm text-green-500 font-medium text-center">
        {copyMessage}
      </p>
    )}

    <div className="flex justify-end gap-4 mt-4">
      <button
        type="button"
        onClick={handleCloseIssueCreatedSuccessfullyDialog}
        className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#9747FF] text-primary-foreground shadow hover:bg-[#9747FF] rounded-md px-6 h-8 text-sm"
      >
        Done
      </button>
    </div>
  </DialogContent>
</Dialog>
            {/* {test_name && pdfFile && testDetails?.result[0]?.passed===100 && <ReportPublishDropdown filePath={pdfFile}  />} */}
            <PDFDownloadLink
              document={<LoadResultPdf data={testDetails} />}
              fileName="LoadResultResult.pdf">
              <Button
                variant="ghost"
                className="text-white h-10 w-60 text-base  bg-[#222938] hover:bg-[#222938] border-[#444444] border-2 hover:text-[#FFFFFF] flex items-center gap-2 font-bold">
                <DownloadIcon size={25} />
                <span className="sr-only sm:not-sr-only  ">Download Report</span>
              </Button>
            </PDFDownloadLink>
            </div>
          </div>

          <Table className="bg-[#11141F] rounded-lg mb-4">
            <TableHeader>
              <TableRow className="border-b border-[#333333]">
                <TableHead className="text-white text-base font-medium p-4 text-left">
                  Test Name
                </TableHead>
                <TableHead className="text-white text-base font-medium p-4 text-left">
                  Number of Virtual Users
                </TableHead>
                <TableHead className="text-white text-base font-medium p-4 text-left">
                  Run At
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className="border-b border-[#333333]">
                <TableCell className="py-2 px-4 text-white">{testDetails.test_name}</TableCell>
                <TableCell className="py-2 px-4 text-white">
                  {testDetails.number_of_virtual_users}
                </TableCell>
                <TableCell className="py-2 px-4 text-white">
                  {new Date(testDetails.created_at).toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold">Output</h1>
                {recommendationData == null && (
                  <Button
                    type="button"
                    className="text-white h-10 w-60 text-base font-medium"
                    onClick={() => getAIRecommendation()}>
                    AI Recommendations
                  </Button>
                )}
              </div>
              {testDetails.result ? (
                <div className='pb-4'>{renderTestDetailsResults()}</div>
              ) : (
                <div></div>
              )}
              <iframe
                src={testDetails.file_path[0].output_path}
                title="File Viewer"
                className="w-full h-[100vw] bg-white"></iframe>

              {/* <embed src="https://bucket.scalesecure.io/scalesecure/output/66f5678f490505fc477f50ce.html?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=t3Lz0fCsBD815IZr8QVZ%2F20240926%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240926T135454Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=1260ef75bbb3f72b26c734dc3ae1a46272b0b90b758409f27560d762fc400a7b" width="800" height="600"></embed> */}
              {/* <a href={testDetails.file_path[0].output_path} target="_blank" rel="noopener noreferrer">


  Open File
</a> */}
            </div>
          </div>
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default withLoader(withUser(LoadTestResults));

import DashTable from '../../components/Admin_UserManagement/DashTable';
import React, { useEffect, useState } from 'react';
import { deleteIcon, download, restart } from '../../assets/icons2/index';
import NormalCard from '../../components/Cards/normal-card';
import ButtonCard from '../../components/Cards/SecurityButtonCard';
import { deleteSecurityTest, getSecurityTestResults, runSecurityTest, runSecurityTestForDocker } from '../../utils/util';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import { useToast } from '../../components/ui/use-toast';

// import Certificate from '../../components/PDF/DownloadCertificate';

const Index = ({ user, setLoader }) => {
  // import { useSelector } from 'react-redux';

  // const collapsed = useSelector((state) => state.toggle);
  const tabsConfig = [{ value: 'security', label: 'Security' }];
  const [page,setPage] = useState(1);
  const [data, setData] = useState(null); // Initialize with null
const [openSort,setopensort]=useState(false)
const{toast}=useToast()
  const columns = [
    { id: 'testDetails', header: 'Test Details' },
    { id: 'type', header: 'Type' },
    { id: 'status', header: 'Status' },
    { id: 'startTime', header: 'Start Time' }
  ];

  // const data = {
  //   security: [
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 1',
  //       url: 'https://security-team1.com',
  //       status: 'Started',
  //       duration: '1h 30m 0s',
  //       startTime: '04/19/2024 08:43:26 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 2',
  //       url: 'https://security-team2.com',
  //       status: 'Not Started',
  //       duration: '2h 15m 0s',
  //       startTime: '05/01/2024 10:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 3',
  //       url: 'https://security-team3.com',
  //       status: 'Success',
  //       duration: '45m 0s',
  //       startTime: '05/15/2024 01:30:45 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 4',
  //       url: 'https://security-team4.com',
  //       status: 'Failed',
  //       duration: '3h 0m 0s',
  //       startTime: '06/01/2024 09:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 5',
  //       url: 'https://security-team5.com',
  //       status: 'Completed',
  //       duration: '1h 20m 0s',
  //       startTime: '06/10/2024 04:45:00 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 6',
  //       url: 'https://security-team6.com',
  //       status: 'Pending',
  //       duration: '2h 0m 0s',
  //       startTime: '07/01/2024 11:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 7',
  //       url: 'https://security-team7.com',
  //       status: 'Completed',
  //       duration: '1h 50m 0s',
  //       startTime: '07/15/2024 03:30:00 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 8',
  //       url: 'https://security-team8.com',
  //       status: 'In Progress',
  //       duration: '2h 30m 0s',
  //       startTime: '08/01/2024 08:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 9',
  //       url: 'https://security-team9.com',
  //       status: 'Failed',
  //       duration: '1h 0m 0s',
  //       startTime: '08/10/2024 02:15:00 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 10',
  //       url: 'https://security-team10.com',
  //       status: 'Completed',
  //       duration: '1h 40m 0s',
  //       startTime: '09/01/2024 10:30:00 AM'
  //     }
  //   ]
  // };

  const [countOfSecurityTest,setCountOfSecurityTest]=useState(0)
  const fetchData = async () => {
    console.log("Page number from sekar ",page)
    
    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        page_number: page,
        per_page: 10,
        object_id: user.object_id,
        subscription_id: user.activated_subscription_id
      };
      const response = await getSecurityTestResults(payload);

      
      setTotalPage(response?.data.total_pages)
      console.log("count:",response?.data.count)
      if(response?.data.count){
          setCountOfSecurityTest(response?.data.count)
          // setCardData([{...cardData,numberOfTest:countOfSecurityTest}])
          // console.log("setCardData([{...cardData,numberOfTest:countOfSecurityTest}])",cardData)
      setData({ security: response.data.data });
      
      }
    
    setLoader(false);
    } catch (err) {
      setLoader(false)
      console.log(err);
    }
  };
  const handleDelete = async (id) => {
    setLoader(true)
    const data = {
      user_id: user.user_id,
      enterprise_id: user.enterprise_id ? user.enterprise_id : null,
      id: id,
      object_id: user.object_id
    };
   
      try {
        const response = await deleteSecurityTest(data);
        console.log("delete th data",response.data);

        //after delete the data ,calling the getsecuritytestresults api for update the data in table 

        const payload = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          page_number: page, // defining page const [page] = useState(1);
          per_page: 10,
          object_id: user.object_id,
          subscription_id: user.activated_subscription_id
        };
        const response1 = await getSecurityTestResults(payload);
       
        console.log("after delete the data updated data",response1.data.data,response1)
        //updatedData comes from parent component through props set the updated data after delete the data
        
        if(response1?.data.count){
          setCountOfSecurityTest(response1?.data.count)
        setData({ security: response1.data.data });
            setInterval(() => {
         setLoader(false) 
        }, 1000);
        }
        
      } catch (error) {
        console.log(error);
      }
    
    } 
  
    const handleRestartTest=async(data)=>{
      setLoader(true)
      console.log("data",data)
      let payload={}
      if(data?.type==="security-website"){
       payload={
        user_id:data?.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        test_name:data?.test_name,
        type:data?.type,
        object_id:user?.object_id,
        subscription_id: user.activated_subscription_id,
        url:data?.url,
        restart:true,
       }
      }
      if(data?.type==="security-api-scan"){
        payload={
          user_id:data?.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          test_name:data?.test_name,
          type:data?.type,
          object_id:user?.object_id,
          subscription_id: user.activated_subscription_id,
          openapi_url:data?.api_dumb_url,
          restart:true,
         }
      }
      if(data?.type==="security-docker-scan"){
        payload={
          user_id:data?.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          test_name:data?.test_name,
          type:data?.type,
          object_id:user?.object_id,
          subscription_id: user.activated_subscription_id,
          scan_type:"image",
          target : data.target,
          restart:true,
         }
      }
      if(data?.type==="gitRepo_scan"){
        payload={
          user_id:data?.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          test_name:data?.test_name,
          type:data?.type,
          object_id:user?.object_id,
          subscription_id: user.activated_subscription_id,
          scan_type:"repo",
          target : data.target,
          restart:true,
         }
      }
      // user_id: user.user_id,
      // enterprise_id: user.enterprise_id ? user.enterprise_id : null,
      // test_name: formData.name,
      // // type: selectedTestTypes.join(', '), // Comma-separated string for types
      // type:selectedTests[0], //here only there only one element that element was which is selected on the checkbox
      // object_id: user.object_id,
      // subscription_id: user.activated_subscription_id,
      // token:selectedTests[0]==="security-api-scan"?formData.bearerToken:"",
      // user_name:selectedTests[0]==="security-api-scan"?formData.username:"",
      // password:selectedTests[0]==="security-api-scan"?formData.password:"",
      // authorization_type:selectedTests[0]==="security-api-scan"?authType:"",
      // url: 'https://heritage-explorer-31.netlify.app',
      
      try {
        let response
        if(data.type==="security-website" || data.type==="security-api-scan"){
          response = await runSecurityTest(payload)
        }
        if(data.type==="security-code-scan" || data.type==="security-docker-scan"){
          response=await runSecurityTestForDocker(payload)
        }
        
        console.log("Respose fron run security test",response)
        if(response){
          return  toast({
            variant: 'success',
            title: 'Success',
            description: "Test Restart Initiated successfully"
          });
        }
      } catch (error) {
        console.log("Error in Server",error)
        throw new Error("Error in restarting this test")
      }
      finally {
        setTimeout(() => setLoader(false), 1000); // Adds a slight delay
      }
    }


  const buttonOptions = [
    {
      icon: download,
      name: 'Download',
      alt: 'Send Icon',
      text: <span className="text-base font-semibold">Download Certificate</span>,
      iconHeight: 4,
      iconWidth: 18,
      iconColor: 'text-[#FFFFFF]'
    },
    {
      icon: deleteIcon,
      alt: '',
      name: 'Delete',
      text: <span className="text-base font-semibold">Delete</span>,
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-[#FFFFFF]',
      actionSecurityDelete:handleDelete
    },
    {
      icon: restart,
      alt: '',
      name: 'Restart',
      text: <span className="text-base font-semibold">Restart</span>,
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-[#FFFFFF]',
      actionSecurityRestartTest:handleRestartTest
    }
  ];

  const accordionTab = [
    { title: 'Title 1', subtitles: ['Subtitle 1.1', 'Subtitle 1.2'] },
    { title: 'Title 2', subtitles: ['Subtitle 2.1', 'Subtitle 2.2'] },
    { title: 'Title 3', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 4', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 5', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 6', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 7', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] }
  ];
  // const [securityAvailableForAsPerPlan,setSecurityAvailableForAsPerPlan]=useState()
 
  const [totalPage,setTotalPage]=useState(1)
  // useEffect(()=>{
  // if(user?.subscription_plan){
  //   if(user?.subscription_plan.toLowerCase()==="basic"){
  //     setSecurityAvailableForAsPerPlan(1)
  //   }
  //   else if(user?.subscription_plan.toLowerCase()==="standard"){
  //     setSecurityAvailableForAsPerPlan(5)
  //   }
  //   else if(user?.subscription_plan.toLowerCase()==="pro"){
  //     setSecurityAvailableForAsPerPlan(30)
  //   }
  //   else{
  //     securityAvailableForAsPerPlan(0)
      
  //   }
  // }
  // },[user])

  
  const url = '/';
  const buttonName = 'Start';
  const buttonTitle = 'Security Test';
  const testName = "API Test";
  const virtualUser = 1000;
  const certificateNumber = "123456789";
  const issueDate = "2024-10-07";
 
  useEffect(() => {
    setLoader(true)
//  setLoader(true);
    fetchData();
  }, [user,countOfSecurityTest]);

  // useEffect(()=>{
  //   if(!openSort){
  //     setInterval(() => {
  //       fetchData
  //     }, 5000);
  //   }
  // },[openSort])

  useEffect(() => {
    fetchData()
    
        const interval = setInterval(() => {
        fetchData();
    }, 15*1000);

    return () => clearInterval(interval);
    
  
  }, [user,page]);

  useEffect(() => {
console.log("PAGE NUMBER SEKAR",page)
    
  
  }, [page]);

 
// useEffect(()=>{
//   setLoader(true)
//    setCardData([
//     { title: 'No of Security', numberOfTest:countOfSecurityTest || 0,
//       // denominator:securityAvailableForAsPerPlan 
//     },
//     // { title: 'Risk', numberOfTest: 32, denominator: 50}
//   ]);
//   setLoader(false)
// },[])

const cardData=[
  { title: 'No of Security', numberOfTest:countOfSecurityTest || 0,
    // denominator:securityAvailableForAsPerPlan 
  },
  // { title: 'Risk', numberOfTest: 32, denominator: 50}
];

  //after delete teh data in dashtable.jsx it should update in index.js 

  const handleUpdateData = (updatedData) => {
    console.log("updated data",updatedData)
    
    setData({ security: updatedData });
  };
  


  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:py-0 md:gap-8 w-full">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        <div className={`flex h-full w-full gap-4`}>
          { cardData.map((card, index) => (
            <NormalCard
              key={index}
              title={card.title}
              numberOfTest={card.numberOfTest}
              denominator={card.denominator}
            />
          ))}
          <ButtonCard
            title={buttonTitle}
            buttonName={buttonName}
            url={url}
            setData={setData}
            page={page}
            setCountOfSecurityTest={setCountOfSecurityTest}
          />
        </div>
        <DashTable
        updatedData={handleUpdateData}
          tabsConfig={tabsConfig}
          columns={columns}
          data={data != 'None' ? (data ? data : {}) : { security: null }}
          buttonType={'Download'}
          buttonOptions={buttonOptions}
          accordionTab={accordionTab}
          page={page}
          setPage={setPage}
          name={'security'}
          count={countOfSecurityTest}
          totalPage={totalPage}
          openSort={openSort}
          setOpenSort={setopensort}
        />
      </div>
      <div>
      {/* <Certificate
        testName={testName}
        virtualUser={virtualUser}
        certificateNumber={certificateNumber}
        issueDate={issueDate}
      /> */}
    </div>
    </main>
  );
};

export default withLoader(withUser(Index));


import React, { useEffect, useState } from 'react';
import { Button } from '../../components/ui/button';
import { Switch } from '../../components/ui/switch-no-label';
import { Label } from '../../components/ui/label';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card';
import {
  BASIC_PLAN_FEATURES,
  STANDARD_PLAN_FEATURES,
  PRO_PLAN_FEATURES,
  ENTERPRISE_PLAN_FEATURES
} from '../../constants';

import checkMark from '../../assets/images/plan-checkmark.svg';
import basicPlanVector from '../../assets/images/basic-plan-vector.svg';
import standardPlanVector from '../../assets/images/standard-plan-vector.svg';
import enterprisePlanVector from '../../assets/images/enterprise-plan-vector.svg';
import { payment } from '../../helpers/payment';
import ProCard from './procard';
import ChatIcon from '../../assets/images/chat-icon.png.png';
import { useSelector } from 'react-redux';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import { useNavigate } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../../components/ui/dropdown-menu';
import DropdownIcon from '../../assets/icons/dropdown-icon';
import { Checkbox } from '../../components/ui/checkbox';
import { basicPlan, getPlanDetails, pausedSubscription, resumedSubscription } from '../../utils/util';

const PlansPage = ({ user,setUser, setLoader }) => {
  const navigate = useNavigate();
  const [isMonthly, setIsMonthly] = useState(true);
  const [isPausedSubcription,setIsPausedSubcription]=useState(user?.activated_subscription_id)
  const collapsed = useSelector((state) => state.toggle);
  const [currencyType, setCurrencyType] = useState('INR');
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [standardPlanId, setStandardPlanId] = useState('');
  const [proPlanId, setProPlanId] = useState('');
  const isSubscriptionNull = !user.plan_type;
  const currentPlan = user.plan_type || '';
  const total_count = isMonthly ? 12 : 1;
 const [planData,setPlanData]=useState([])
  const handleCurrencyDropdown = (open) => {
    setCurrencyDropdownOpen(open);
  };

  const handleCurrencyChange = (currency) => {
    setCurrencyType(currency);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      console.log(user);
      try {
        const payload = {
          enterprise_id: user.enterprise_id ? user.enterprise_id : 'scalesecure',
          // plan_name: user.plan_type,
          // currency: currencyType
          plan_name: null,
          currency:null
        };

        const response = await getPlanDetails(payload);
        setPlanData(Array.isArray(response?.data.data) && response?.data?.data.length>0 ? response?.data.data : [])
        const prices = {};
        if(Array.isArray(response?.data.data) && response?.data?.data.length>0)
        {  response?.data?.data.forEach(({ plan_name, mode, currency, pricing,plan_id }) => {
            const curr = currency  // Fix typo
            const period = mode === "monthly" ? "monthly" : "annual";
        
            if (!prices[plan_name.toLowerCase()]) {
                prices[plan_name.toLowerCase()] = {};
            }
        
            if (!prices[plan_name.toLowerCase()][curr]) {
                prices[plan_name.toLowerCase()][curr] = {};
            }
        
            prices[plan_name.toLowerCase()][curr][period] = pricing;
            prices[plan_name.toLowerCase()][period]=plan_id
        });
        
        console.log(prices);
        setFormatedPlanData(prices)
      }
        setStandardPlanId(response.data.data[1].plan_id);
        setProPlanId(response.data.data[2].plan_id);
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [user, currencyType]);

  const [formatedPlanData,setFormatedPlanData]=useState({})

  const getPrice = (plan) => {
    // const prices = {
    //   standard: {
    //     USD: { monthly: 59, annual: 50 },
    //     INR: { monthly: 4999, annual: 4199 }
    //   },
    //   pro: {
    //     USD: { monthly: 99, annual: 84 },
    //     INR: { monthly: 8399, annual: 7099 }
    //   }
    // };

    const prices = formatedPlanData;
if(planData?.length>0)
{ 
//    planData.forEach(({ plan_name, mode, currency, pricing,plan_id }) => {
//     const curr = currency  // Fix typo
//     const period = mode === "monthly" ? "monthly" : "annual";

//     if (!prices[plan_name.toLowerCase()]) {
//         prices[plan_name.toLowerCase()] = {};
//     }

//     if (!prices[plan_name.toLowerCase()][curr]) {
//         prices[plan_name.toLowerCase()][curr] = {};
//     }

//     prices[plan_name.toLowerCase()][curr][period] = pricing;
//     prices[plan_name.toLowerCase()][period]=plan_id
// });

// console.log(prices);
// setFormatedPlanData(prices)
    const period = isMonthly ? 'monthly' : 'annual';
    return prices[plan][currencyType][period];
  }
  };


  const handleBasic = async () => {
    setLoader(true);
    try {
      const response = await basicPlan({ user_id: user.user_id });
      console.log(response);
      if(response){
        setUser({...user,activated_subscription_id:response.data.subscription_id,subscription_plan:response.data.Plan_type,plan_type:response.data.Plan_type
        })
      }
      setLoader(false);
      navigate('/');
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleStandard = async () => {
    setLoader(true);
    const amount=getPrice("standard")
    console.log("amount",amount)

    const StandardPlanId=formatedPlanData["standard"][isMonthly?"monthly":"annual"]

    await payment(StandardPlanId, user, navigate, total_count,amount);
    setLoader(false);
  };

  const handlePro = async () => {
    setLoader(true);
    const ProPlanId=formatedPlanData["pro"][isMonthly?"monthly":"annual"]
    await payment(ProPlanId, user, navigate, total_count);
    setLoader(false);
  };


  const handleSubscriptionAction=async(isPaused)=>{
    try {
      setLoader(true)
      const payload={
        subscription_id:user?.activated_subscription_id,
        user_id:user?.user_id,
      }
      if(isPaused){
        const pauseRes=await pausedSubscription(payload)
        console.log("paused Response",pauseRes)
        setLoader(false)
      }
      if(!isPaused){
        const resumeRes=await resumedSubscription(payload)
        console.log("paused Response",resumeRes)
        setLoader(false)
      }
      console.log("userDetails",user)
    } catch (error) {
      console.log("Error in HandleSubscriptionAction API",error)
    }
  }

  console.log(collapsed);

  // console.log(user);


  return (
    <main className="grid flex-1 items-start gap-4 p-2 sm:py-0 md:gap-[3.88rem]">
    
    
      <div className="flex flex-col gap-20">
        {/* Pricing Switch */}


        <div className="flex w-full items-center justify-between pt-4 manrope-fontCss">
          <div>
            <h1 className="text-base font-bold">Upgrade & Get more out of Scale Secure</h1>
            <h2 className="text-sm font-normal text-[#868686] mt-1 mb-2">
            Elevate your security and efficiency with our tailored plans.
            </h2>
          </div>

          <div className="flex space-x-3">
            {/* Currency Type */}
            {/* <DropdownMenu className="" onOpenChange={handleCurrencyDropdown}>
              <DropdownMenuTrigger className="h-9 w-56 bg-[#2A2E3F] text-white border-[#444444] rounded-lg mt-2">
                <div className="px-[13px] flex items-center justify-between">
                  {currencyType === 'USD' ? 'USD($)' : 'INR (₹)'}{' '}
                  <div
                    className={`transition-transform ${currencyDropdownOpen ? 'rotate-180' : ''}`}>
                    <DropdownIcon />
                  </div>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#2A2E3F] mt-4">
                <DropdownMenuItem
                  onClick={() => handleCurrencyChange('USD')}
                  className={currencyType === 'USD' ? 'bg-accent' : ''}>
                  <Checkbox id="usd" checked={currencyType === 'USD'} />
                  <label
                    htmlFor="usd"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    <div className="w-full text-center text-[16px] font-semibold ml-2">USD</div>
                  </label>
                </DropdownMenuItem>
                <DropdownMenuSeparator className="" />
                <DropdownMenuItem
                  onClick={() => handleCurrencyChange('INR')}
                  className={currencyType === 'INR' ? 'bg-accent' : ''}>
                  <Checkbox id="inr" checked={currencyType === 'INR'} />
                  <label
                    htmlFor="inr"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    <div className="w-full text-center text-[16px] font-semibold ml-2">INR</div>
                  </label>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> */}
<div className="flex flex-row gap-2 items-center justify-center">
              <div className="flex flex-row gap-1 items-center">
                <Label htmlFor="pricing-method" className="text-sm">
                  Resumed
                </Label>
                <Switch
                  id="pricing-method"
                  className="bg-background"
                  // value={isPausedSubcription}
                  checked={isPausedSubcription}
                  onCheckedChange={() => {
                    
                    setIsPausedSubcription(!isPausedSubcription)
                  const isPaused=!isPausedSubcription
                  handleSubscriptionAction(isPaused)
                  }}
                />
                <Label htmlFor="pricing-method" className="text-sm">
                  Paused
                </Label>
              </div>
            </div>
<button className="h-9 w-56 bg-[#2A2E3F] text-white border-[#444444] rounded-lg mt-2 flex items-center justify-between px-[13px]">
  <div className="w-full text-center text-[16px] font-semibold">INR (₹)</div>
</button>


            {/* Payment Type */}
            <div className="flex flex-row gap-2 items-center justify-center">
              <div className="flex flex-row gap-1 items-center">
                <Label htmlFor="pricing-method" className="text-sm">
                  Pay Monthly
                </Label>
                <Switch
                  id="pricing-method"
                  className="bg-background"
                  value={isMonthly}
                  onCheckedChange={() => setIsMonthly(!isMonthly)}
                />
                <Label htmlFor="pricing-method" className="text-sm">
                  Pay Annually
                </Label>
              </div>
            </div>
          </div>
        </div>

        {/* Plan Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mb-20 h-auto">
          {/* Basic Card */}
          <div className={`h-auto`}>
            <Card>
              <CardHeader className="flex flex-row items-center gap-2 space-y-0 p-4">
                <div className="bg-[#11141F] rounded-[4rem] p-4 w-fit">
                  <img src={basicPlanVector} className="w-5 h-5 object-cover" />
                </div>
                <h3 className="font-bold text-xl">Basic</h3>
              </CardHeader>
              <CardContent className="space-y-4">
                <div>
                  <h4 className="text-2xl font-bold">FREE</h4>
                  <div className={`text-[#909095] mt-2 ${!collapsed ? 'text-xs' : 'text-base'}`}>
                    Per user, per month
                  </div>
                </div>
                <Button
                  variant="outline"
                  className={`mt-4 w-full py-7 p-5 text-xl hover:text-white font-semibold bg-white text-black  ${!collapsed ? 'text-sm' : 'text-xl'}`}
                  onClick={handleBasic}
                  disabled={currentPlan === 'Basic' && !isSubscriptionNull}>
                  {currentPlan === 'Basic' ? 'Current Plan' : 'Subscribe'}
                </Button>
                <p className={`font-semibold ${!collapsed ? 'text-sm' : 'text-base'}`}>
                  For Individual Enterpreneurs
                </p>
                <div className="space-y-4">
                  {BASIC_PLAN_FEATURES.map((feature, index) => (
                    <div className="flex flex-row items-center" key={index}>
                      <img src={checkMark} height={16} width={16} />
                      <p className="text-[#868686] text-xs ml-3">{feature}</p>
                    </div>
                  ))}
                </div>
              </CardContent>
              <CardFooter></CardFooter>
            </Card>
          </div>

          {/* Standard Card */}
          <div className={`h-auto`}>
            <Card>
              <CardHeader className="flex flex-row items-center gap-3 space-y-0 p-4">
                <div className="bg-[#11141F] rounded-[4rem] p-4 w-fit">
                  <img src={standardPlanVector} className="w-5 h-5 object-cover" />
                </div>
                <h3 className="font-bold text-xl">Standard</h3>
              </CardHeader>
              <CardContent className="space-y-4">
                <div>
                  <div className="flex flex-row items-end">
                    <h4 className="text-2xl font-bold">
                      {currencyType === 'USD' ? '$' : '₹'}
                      {getPrice('standard')}
                    </h4>
                    <div className="ml-2 text-lg text-[#909095]">{isMonthly ? '/month' : '/annual'}</div>
                  </div>
                  <div className={`text-[#909095] mt-2 ${!collapsed ? 'text-xs' : 'text-base'}`}>
                    Per user, per {isMonthly ? 'month' : 'annual'}, billed {isMonthly ? 'monthly' : 'annually'}
                  </div>
                </div>
                <Button
                  variant="outline"
                  className={`mt-4 w-full py-7 p-5 text-xl hover:text-white font-semibold bg-white text-black ${!collapsed ? 'text-sm' : 'text-xl'}`}
                  onClick={handleStandard}
                  disabled={currentPlan === 'Standard' && !isSubscriptionNull}>
                  {currentPlan === 'Standard' && !isSubscriptionNull ? 'Current Plan' : 'Subscribe'}
                </Button>
                <p className={`font-semibold ${!collapsed ? 'text-sm' : 'text-base'}`}>
                  For Growing Teams
                </p>
                <div className="space-y-4">
                  {STANDARD_PLAN_FEATURES.map((feature, index) => (
                    <div className="flex flex-row items-center" key={index}>
                      <img src={checkMark} height={16} width={16} />
                      <p className="text-[#868686] text-xs ml-3">{feature}</p>
                    </div>
                  ))}
                </div>
              </CardContent>
              <CardFooter></CardFooter>
            </Card>
          </div>

          {/* Pro Card */}
          <ProCard
            isMonthly={isMonthly}
            currencyType={currencyType}
            price={getPrice('pro')}
            handlePro={handlePro}
            PRO_PLAN_FEATURES={PRO_PLAN_FEATURES}
            currentPlan={currentPlan}
            isSubscriptionNull={isSubscriptionNull}
          />
          {/* Enterprise Card */}
          <Card>
            <CardHeader className="flex flex-row items-center gap-3 space-y-0 p-4">
              <div className="bg-[#11141F] rounded-[4rem] p-4 w-fit">
                <img src={enterprisePlanVector} className="w-5 h-5 object-cover" />
              </div>
              <h3 className="font-bold text-xl">Enterprise</h3>
            </CardHeader>
            <CardContent className="space-y-4">
              <div>
                <div className="flex flex-row items-end">
                  <div className="ml-0 ">
                    <img src={ChatIcon} alt="Chat" height={35} width={35} />
                  </div>
                </div>
                <div className={`text-[#909095] mt-2 ${!collapsed ? 'text-xs' : 'text-base'}`}>
                  Custom pricing, per user
                </div>
              </div>

              <Button
                variant="outline"
                className={`mt-4 w-full py-5 bg-white text-black font-semibold hover:text-white ${!collapsed ? 'text-sm' : 'text-xl'}`}>
                Connect with us
              </Button>
              <p className={`font-semibold ${!collapsed ? 'text-sm' : 'text-base'}`}>
                For Large Organisations
              </p>
              <div className="space-y-4">
                {ENTERPRISE_PLAN_FEATURES.map((feature, index) => (
                  <div className="flex flex-row items-center" key={index}>
                    <img src={checkMark} height={16} width={16} />
                    <p className="text-[#868686] text-xs ml-3">{feature}</p>
                  </div>
                ))}
              </div>
            </CardContent>
            <CardFooter></CardFooter>
          </Card>
        </div>
      </div>
    </main>
  );
};

export default withLoader(withUser(PlansPage));


import React from 'react';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card';
import Star from '../../assets/icons/star';
import proPlanVector from '../../assets/images/pro-plan-vector.svg';
import { Button } from '../../components/ui/button';
import checkMarkWhite from '../../assets/images/plan-checkmark-white.svg';
import { useSelector } from 'react-redux';

const BackgroundStars = () => {
  const starCount = 5;

  const stars = Array.from({ length: starCount }).map((_, index) => {
    const left = Math.random() * 100; // Random x coordinate in percentage
    const top = Math.random() * 100; // Random y coordinate in percentage
    return (
      <Star
        key={index}
        className="absolute"
        style={{
          left: `${left}%`,
          top: `${top}%`,
          transform: 'translate(-50%, -50%)', // Center star at position
          opacity: 0.3, // Optional: Make stars semi-transparent
          fontSize: '100px' // Adjust size of stars as needed
        }}
      />
    );
  });

  return <div className="absolute inset-0 overflow-hidden">{stars}</div>;
};

const ProCard = ({
  isMonthly,
  currencyType,
  price,
  handlePro,
  PRO_PLAN_FEATURES,
  currentPlan,
  isSubscriptionNull
}) => {
  const collapsed = useSelector((state) => state.toggle);

  return (
    <div className="h-auto relative">
      <BackgroundStars />

      <Card className="bg-gradient-to-b from-[#9747FF] to-[#A0B4FF] xl:relative xl:top-[-1.5rem] relative">
        <CardHeader className="relative flex flex-row items-center gap-3 space-y-0 p-4 justify-between z-10">
          <div className="flex flex-row items-center gap-3 space-y-0">
            <div className="bg-[#11141F] rounded-[4rem] p-4 w-fit">
              <img src={proPlanVector} className="w-5 h-5 object-cover" />
            </div>
            <h3 className="font-bold text-xl">Pro</h3>
          </div>
          <div className="bg-[#6D2CC2] rounded-md p-1 flex items-center justify-center">
            <span className={`text-white m-1 ${!collapsed ? 'text-xs' : 'text-sm'}`}>
              Most Popular
            </span>
          </div>
        </CardHeader>
        <CardContent className="relative space-y-4 z-10">
          <div className="flex flex-row justify-between">
            <div>
              <div className="flex flex-row items-end">
                <h4 className="text-2xl font-bold">
                  {currencyType === 'USD' ? '$' : '₹'}
                  {price}
                </h4>
                <div className="ml-2 text-lg">{isMonthly ? '/month' : '/annual'}</div>
              </div>
              <div className={`text-white mt-2 ${!collapsed ? 'text-xs' : 'text-base'}`}>
                Per user, per {isMonthly ? 'month' : 'annual'}, billed {isMonthly ? 'monthly' : 'annually'}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <Button
              variant="outline"
              className={`mt-2 w-full py-7 p-5  font-semibold hover:text-white ${!collapsed ? 'text-sm' : 'text-xl'}`}
              onClick={handlePro}
              disabled={currentPlan === 'Pro' && !isSubscriptionNull}>
              {currentPlan === 'Pro' && !isSubscriptionNull ? 'Current Plan' : 'Subscribe'}
              {/* Upgrade to Pro */}
            </Button>
          </div>
          <p className={`font-semibold ${!collapsed ? 'text-sm' : 'text-base'}`}>
            For Scaling Businesses
          </p>
          <div className="space-y-4">
            {PRO_PLAN_FEATURES.map((feature, index) => (
              <div className="flex flex-row items-center" key={index}>
                <img src={checkMarkWhite} height={16} width={16} />
                <p className="text-xs ml-3">{feature}</p>
              </div>
            ))}
          </div>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
};

export default ProCard;

import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { CircleHelp, CircleMinus, FilePlus2Icon, Plus, Trash2, } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { LOAD_TEST_BODY_TYPE, LOAD_TEST_METHODS } from '../../constants';
import arrayMutators from 'final-form-arrays';
import { Label } from '../../components/ui/label';
import { renderField } from '../../helpers/form-validations';
import { Input } from '../../components/ui/input';
// import { Slider } from '../../components/ui/slider';
// import { Switch } from '../../components/ui/switch';
import { Checkbox } from '../../components/ui/checkbox';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/button';
import Box from '@mui/material/Box';  // MUI Box
import Typography from '@mui/material/Typography';  // MUI Typography
import Modal from '@mui/material/Modal';  // MUI Modal

import { loadTest, getEnvironmentNames } from '../../utils/util';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue
// } from '../../components/ui/select';
import { Card,CardContent } from '../../components/ui/card';
import CurlImportDialog from '../../components/ui/curl-import-dialog';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/Accordion';
import { environments } from 'eslint-plugin-prettier';

// import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
// import { Switch } from '../../components/ui/switch';
// import { logDOM } from '@testing-library/react';
// import Modal from '../../components/ui/modal';  // Import the Modal component

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto'
};
const bodyStyle = {
  maxHeight: '60vh',
  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
};


const LoadTestForm = ({ user, setLoader }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [payloadData, setPayloadData] = useState([])
  const [apiResponse, setApiResponse] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [curlInput, setCurlInput] = useState("");

  const [isCurlDialogOpen, setIsCurlDialogOpen] = useState(false);

	const handleOpenDialog = (index) => {
		setIsCurlDialogOpen(true)
    setForWhichIndexCurlIsOpened(index)
	};
	const handleCloseDialog = () =>{ 
		setIsCurlDialogOpen(false)
		setCurlInput("")
	};

  const onSubmit = async (ArrayformData,index) => {
    const formData=ArrayformData[index]

  console.log("Load ", LOAD_TEST_AUTH_TYPE);
  console.log("Load ", formData);
  console.log("load method ", LOAD_TEST_METHODS);
  console.log("is dynamic ", formData?.isBodyDynamic);

  

  try {
      const payload = {
        // user_id: user?.user_id,
         // subscription_id: user?.activated_subscription_id,
        // object_id: user?.object_id,
        // virtual_user: formData?.noOfUsers,
           // test_name: formData?.testName,
        // enterprise_id: user?.enterprise_id,
        apiId:formData?.apiId,
        url: formData?.apiPath,
        method: formData?.httpMethod,
        authorization: [
          {
            authorizationType: formData?.authType,
            userName: formData?.authType === LOAD_TEST_AUTH_TYPE[1].value ? formData?.username : '',
            password: formData?.authType === LOAD_TEST_AUTH_TYPE[1].value ? formData?.password : '',
            Token: formData?.authType === LOAD_TEST_AUTH_TYPE[2].value ? formData?.bearer : ''
          }
        ],
     
        headers: formData?.headerValues
          .filter(({ key, value }) => key && value) // Filter out empty key-value pairs
          .map(({ key, value }) => ({ key, value })), // Map valid headers to payload
        queryParams: formData?.paramValues
          .filter(({ key, value }) => key && value) // Filter out empty key-value pairs
          .map(({ key, value }) => ({ key, value })), // Map valid query params to payload
        restart: false,

        // Transform keyTypes into the correct format for dynamic_values
        dynamics_values: Object.entries(keyTypes).map(([key, type]) => ({
          key: key, // The key as entered by the user
          type: type.charAt(0).toLowerCase() + type.slice(1) // Ensure the first letter is lowercase
        })),
       
        
      };

  
      // Only stringify if the HTTP method is POST or PUT
      if (
        formData?.httpMethod === LOAD_TEST_METHODS[1].method ||
        formData?.httpMethod === LOAD_TEST_METHODS[3].method
      ) {
        payload.body = formData?.bodyStaticValue; // Keep it as an object
      }
  
   
      
  
      // let request = {
      //   method: formData?.httpMethod,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     ...formData?.headers?.reduce((acc, header) => {
      //       acc[header.key] = header.value;
      //       return acc;
      //     }, {})
      //   }
      // };

      let request = {
        method: formData?.httpMethod ==="del" ? "DELETE" : formData?.httpMethod,
        headers: {
          // 'Content-Type': 'application/json',
          ...(formData?.httpMethod !== 'del' ? { 'Content-Type': 'application/json' } : {}),
          // Spread formData headers
          ...formData?.headers?.reduce((acc, header) => {
            acc[header.key] = header.value;
            return acc;
          }, {}),
          // Add Bearer token if it exists
          ...(formData?.bearer ? { 'Authorization': `Bearer ${formData.bearer}` } : {})
        }
      };
      // let request = {
      //   method: 'DELETE', // Ensure method is DELETE
      //   headers: {
      //     // Only include 'Content-Type' if necessary
      //     // ...(formData?.httpMethod !== 'DELETE' ? { 'Content-Type': 'application/json' } : {}),
      //     // Spread formData headers
      //     ...formData?.headers?.reduce((acc, header) => {
      //       if (header.key && header.value) {
      //         acc[header.key] = header.value; // Add only valid headers
      //       }
      //       return acc;
      //     }, {}),
      //     // Add Bearer token if it exists
      //     ...(formData?.Bearer ? { 'Authorization': `Bearer ${formData.Bearer}` } : {})
      //   }
      // };
      
     
      
  
      if (
        formData?.httpMethod === LOAD_TEST_METHODS[1].method || 
        formData?.httpMethod === LOAD_TEST_METHODS[3].method
      ) {
        console.log(" JSON.stringify(payload.body)", (payload.body))
        console.log(" JSON.stringify(payload.body)", (formData?.bodyStaticValue))
        
        request.body = (payload.body); // Only stringify the body here



      }
  
      setLoader(true);
      const response = await fetch(formData?.apiPath, request);
      // let response;
      // try {
      //   response = await fetch(formData?.apiPath || 'https://fakestoreapi.com/products/7', request);
      //   if (!response.ok) {
      //     throw new Error(`Request failed with status ${response.status}`);
      //   }
      //   console.log("Response:", await response.json());
      // } catch (error) {
      //   console.error("Error:", error);
      // }
      setLoader(false);
  

      let responseData;
      
        console.log("bearer token ",formData?.bearer)
          try {
        responseData = await response.clone().json();
      } catch (error) {
        try {
          responseData = await response.clone().text();
        } catch (e) {
          console.log(e);
          responseData = null;
        }
      }
      setApiResponse(responseData);
      
    payload.isValidated=true

    const updatedLoadTestRestApiFormState=loadTestRestApiFormState

    updatedLoadTestRestApiFormState[index].isValidated=true

    setLoadTestRestApiFormState(updatedLoadTestRestApiFormState)



console.log("isvalidated",payload,updatedLoadTestRestApiFormState)
      setPayloadData((prev) => {
        const index = prev.findIndex((item) => item.apiId === payload.apiId);
        
        if (index !== -1) {
          // If apiId exists, update the object
          const updatedArray = [...prev];
          updatedArray[index] = payload; // Replace the object at the existing index
          return updatedArray;
        }
      
        // If apiId doesn't exist, add the new object
        return [...prev, payload];
      });

      handleOpen();
    } catch (e) {
      console.log('Error', e);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: e.message
      });
    }

  // try {
  //   // Initialize an array to hold the payloads for each form
  //   const payloads = loadTestRestApiFormState.map((formData, index) => {
  //     const payload = {
  //       user_id: user?.user_id,
  //       url: formData?.apiPath,
  //       virtual_user: formData?.noOfUsers,
  //       method: formData?.httpMethod,
  //       authorization: [
  //         {
  //           authorizationType: formData?.authType,
  //           userName: formData?.authType === LOAD_TEST_AUTH_TYPE[1].value ? formData?.username : '',
  //           password: formData?.authType === LOAD_TEST_AUTH_TYPE[1].value ? formData?.password : '',
  //           Token: formData?.authType === LOAD_TEST_AUTH_TYPE[2].value ? formData?.Bearer : ''
  //         }
  //       ],
  //       test_name: formData?.testName,
  //       enterprise_id: user?.enterprise_id,
  //       headers: formData?.headerValues
  //         .filter(({ key, value }) => key && value) // Filter out empty key-value pairs
  //         .map(({ key, value }) => ({ key, value })), // Map valid headers to payload
  //       queryParams: formData?.paramValues
  //         .filter(({ key, value }) => key && value) // Filter out empty key-value pairs
  //         .map(({ key, value }) => ({ key, value })), // Map valid query params to payload
  //       restart: false,
  
  //       // Transform keyTypes into the correct format for dynamic_values
  //       dynamics_values: Object.entries(formData.bodyDynamicValue || {}).map(([key, type]) => ({
  //         key: key, // The key as entered by the user
  //         type: type.charAt(0).toLowerCase() + type.slice(1) // Ensure the first letter is lowercase
  //       })),
  //       subscription_id: user?.activated_subscription_id,
  //       object_id: user?.object_id,
  //     };
  
  //     // Only stringify if the HTTP method is POST or PUT
  //     if (
  //       formData?.httpMethod === LOAD_TEST_METHODS[1].method ||
  //       formData?.httpMethod === LOAD_TEST_METHODS[3].method
  //     ) {
  //       payload.body = formData?.bodyStaticValue; // Keep it as an object
  //     }
  
  //     return payload;
  //   });
  
  //   // Prepare the request for each form
  //   let requests = payloads.map(payload => {
  //     let request = {
  //       method: payload.httpMethod === "del" ? "DELETE" : payload.httpMethod,
  //       headers: {
  //         ...(payload.httpMethod !== 'del' ? { 'Content-Type': 'application/json' } : {}),
  //         ...payload.headers?.reduce((acc, header) => {
  //           acc[header.key] = header.value;
  //           return acc;
  //         }, {}),
  //         ...(payload.Bearer ? { 'Authorization': `Bearer ${payload.Bearer}` } : {})
  //       }
  //     };
  
  //     // Add the body if it's POST or PUT
  //     if (payload.body) {
  //       request.body = payload.body; // Only stringify the body here
  //     }
  
  //     return fetch(payload.url, request);
  //   });
  
  //   setLoader(true);
  
  //   // Perform all requests concurrently
  //   const responses = await Promise.all(requests);
  
  //   // Process the responses
  //   const responseData = await Promise.all(
  //     responses.map(async (response) => {
  //       try {
  //         return await response.json(); // Try parsing as JSON
  //       } catch (error) {
  //         try {
  //           return await response.text(); // Fallback to plain text
  //         } catch (e) {
  //           console.log(e);
  //           return null; // If both parsing attempts fail
  //         }
  //       }
  //     })
  //   );
  
  //   // Update the API response state for each form
  //   setApiResponse(responseData);
  //   handleOpen();
    
  // }
  
  // catch (e) {
  //   console.log('Error', e);
  //   toast({
  //     variant: 'destructive',
  //     title: 'Error',
  //     description: e.message
  //   });
  // } finally {
  //   setLoader(false);
  // }
  

  };
  

  const renderResponse = () => {
    try {
      let res = JSON.stringify(apiResponse, null, 2)
      return res
    } catch (err) {
      return apiResponse
    }
  }


  const ALGORITHM_OPTIONS = [
    { value: 'md5', label: 'MD5' },
    { value: 'sha256', label: 'SHA256' },
    { value: 'sha756', label: 'SHA756' }
  ];

  const handleKeyValueBlurOrChange = (fields, index, values) => {
    const currentPair = values.headerValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const handleHeaderKeyValueBlurOrChange = (formIndex, fieldArrayIndex, field, value) => {
    const updatedForms = [...loadTestRestApiFormState];
    updatedForms[formIndex].headerValues[fieldArrayIndex][field] = value;
    setLoadTestRestApiFormState(updatedForms);

    // Check if both 'key' and 'value' are filled and add a new empty pair if true
    const form = updatedForms[formIndex];
    const currentHeaderValues = form.headerValues;
    const currentKeyValue = currentHeaderValues[currentHeaderValues.length - 1];

    if (currentKeyValue.key && currentKeyValue.value) {
      // If last key-value pair is filled, add a new empty pair
      updatedForms[formIndex].headerValues.push({ key: '', value: '' });
      setLoadTestRestApiFormState(updatedForms);
    }
  };
  const handleParamsKeyValueBlurOrChange = (formIndex, fieldArrayIndex, field, value) => {
    const updatedForms = [...loadTestRestApiFormState];
    updatedForms[formIndex].paramValues[fieldArrayIndex][field] = value;
    setLoadTestRestApiFormState(updatedForms);

    // Check if both 'key' and 'value' are filled and add a new empty pair if true
    const form = updatedForms[formIndex];
    const currentparamValues = form.paramValues;
    const currentKeyValue = currentparamValues[currentparamValues.length - 1];

    if (currentKeyValue.key && currentKeyValue.value) {
      // If last key-value pair is filled, add a new empty pair
      updatedForms[formIndex].paramValues.push({ key: '', value: '' });
      setLoadTestRestApiFormState(updatedForms);
    }
  };
  const onChangeParamsValue = (fields, index, values) => {
    const currentPair = values.paramValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  // const onChangeBodyTypeValue = (fields, index, values) => {
  //   const currentPair = values.bodyDynamicValue[index];
  //   if (
  //     currentPair &&
  //     (currentPair.name || currentPair.type || currentPair.options) &&
  //     index === fields.length - 1
  //   ) {
  //     fields.push({ name: '', type: '', options: true });
  //   }
  // };
  // const [selectedMethod, setSelectedMethod] = useState(null);
  const AUTHORIZATION_TYPES = [
    { value: 'noAuth', label: 'No Auth' },
    { value: 'basicAuth', label: 'Basic Auth' },
    { value: 'Bearer', label: 'Bearer Token' },
    // { value: 'jwtbearer', label: 'JWT Bearer' },
    // { value: 'digitalauth', label: 'Digital Auth' },
    // { value: 'oauth1', label: 'OAuth 1.0' },
    // { value: 'oauth2', label: 'OAuth 2.0' },
    // { value: 'hawk', label: 'Hawk Authentication' },
    // { value: 'awssign', label: 'AWS Signature' },
    // { value: 'nltm', label: 'NLTM Authentication' }
  ];

  const [authType, setAuthType] = useState('');

  const LOAD_TEST_AUTH_TYPE = AUTHORIZATION_TYPES;

  const required = (value) => (value ? undefined : 'Required');

  const composeValidators =
    (...validators) =>
      (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

  // const [values, setValues] = useState({
  //   username: '',
  //   password: '',
  //   Bearer: ''
  // });
  // const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);

  const [parsedKeys, setParsedKeys] = useState([]);
  // Function to set keys at a specific index
const updateParsedKeys = (index, keys) => {
  console.log("KEYS",keys)
  setParsedKeys((prev) => {
    // Create a copy of the previous state
    const updatedKeys = [...prev];
    
    // Set the keys at the specific index
    updatedKeys[index] = keys;
    
    // Return the updated array
    return updatedKeys;
  });
};
  const [envNames, setEnvNames] = useState([]);
  const [keyTypes, setKeyTypes] = useState({});

  const [formState, setFormState] = useState({

    httpMethod: LOAD_TEST_METHODS[0].method,
    authType: LOAD_TEST_AUTH_TYPE[0].type,
    bodyType: LOAD_TEST_BODY_TYPE[0].type,
    // headerValues: [{ key: '', value: '' }],
    // paramValues: [{ key: '', value: '' }],
    // // bodyDynamicValue: [{ name: '', type: '', isDynamic: true }],
    // bodyDynamicValue: [{ key: '', type: '' }],
    headerValues: [{ key: '', value: '' }],
    paramValues: [{ key: '', value: '' }],
    // bodyDynamicValue: [{ name: '', type: '', isDynamic: true }],
    bodyDynamicValue: [{ key: '', type: '' }],
    isBodyDynamic: false,
    bodyStaticValue: '',
    environment: '',
    testName:"",
    noOfUsers:""

  })

  const [loadTestRestApiFormState, setLoadTestRestApiFormState] = useState(
   [{
    apiId: `api-${Date.now()}`, // Generate a unique ID
    httpMethod: LOAD_TEST_METHODS[0].method,
    authType: LOAD_TEST_AUTH_TYPE[0].type,
    bodyType: LOAD_TEST_BODY_TYPE[0].type,
    headerValues: [{ key: '', value: '' }],
    paramValues: [{ key: '', value: '' }],
    bodyDynamicValue: [{ key: '', type: '' }],
    isBodyDynamic: false,
    bodyStaticValue: '',
    username:"",
    password:"",
    bearer:"",
    isValidated:false
   }
   ]
  )

const [basicInfo,setBasicInfo]=useState({
  testName:"",
  noOfUsers:""
})

  const handleShowForm = () => {

    const invalidIndex = loadTestRestApiFormState.findIndex(form => !form.isValidated);

    if (invalidIndex !== -1) {
      toast({
        description: `Form of Rest Api ${invalidIndex + 1} is not validated. Please Validate it before adding a new Rest API form.`,
        variant: 'destructive',
        title: 'Error',
      });
      return;
    }

    setLoadTestRestApiFormState([
      ...loadTestRestApiFormState,
      {
        apiId: `api-${Date.now()}`, // Generate a unique ID
        httpMethod: LOAD_TEST_METHODS[0].method,
        authType: LOAD_TEST_AUTH_TYPE[0].type,
        bodyType: LOAD_TEST_BODY_TYPE[0].type,
        headerValues: [{ key: '', value: '' }],
        paramValues: [{ key: '', value: '' }],
        bodyDynamicValue: [{ key: '', type: '' }],
        isBodyDynamic: false,
        bodyStaticValue: '',
        username:"",
        password:"",
        bearer:"",
        isValidated:false
      }
    ]);
    
  };

  const handleChange = (index, field, value) => {

    const updatedForms = [...loadTestRestApiFormState];
    updatedForms[index][field] = value;
    setLoadTestRestApiFormState(updatedForms);
    console.log("updated Form Field",updatedForms)
  };
 
  const [forWhichIndexCurlIsOpened,setForWhichIndexCurlIsOpened]=useState()

	  const handleParseCurl = (curlInput,index) => {
		try {
		  const parseCurlCommand = (curlCommand) => {
			// Extract URL
			const urlRegex = /curl --location '(.*?)'/;
			const urlMatch = curlCommand.match(urlRegex);
			const url = urlMatch ? urlMatch[1] : null;
	  
			// Determine HTTP Method
			let method = "GET";
			if (curlCommand.includes("--form") || curlCommand.includes("--data")) {
			  method = "POST";
			}
	  
			// Extract Headers
			const headerRegex = /--header '(.*?): (.*?)'/g;
			const headers = {};
			let match;
			while ((match = headerRegex.exec(curlCommand)) !== null) {
			  headers[match[1]] = match[2];
			}
	  
			// Extract Form Data
	  // Extract Form Data
    const formRegex = /--form '(.*?)=(.*?)'/g;
    const formData = {};
    while ((match = formRegex.exec(curlCommand)) !== null) {
      const key = match[1];
      const value = match[2].startsWith("@") ? `File: ${match[2].slice(1)}` : match[2];
      formData[key] = value;
    }

    // Extract Raw Data (JSON)
    const rawDataRegex = /--data(?:-raw)?\s+'(.*?)'/s;

    const rawDataMatch = curlCommand.match(rawDataRegex);
    const rawData = rawDataMatch ? JSON.parse(rawDataMatch[1]) : null;

    // Merge rawData into formData if present
    if (rawData) {
      Object.assign(formData, rawData);
    }
	  
			return { url, method, headers, formData };
		  };
	  
		  const ArrayOfAuthType=["Bearer","Basic"]
		  
		  const parsedData = parseCurlCommand(curlInput);
		  console.log("Parsed Data:", parsedData);
	   
      let authType = "noAuth";
      let token = null; // Placeholder for token extraction
	
		// Check if authorization header exists
		if (parsedData.headers && parsedData.headers.authorization) {
		  const authorizationHeader = parsedData.headers.authorization;
	  
		  // Check if the authorization type matches any in the ArrayOfAuthType
		  const matchedAuthType = ArrayOfAuthType.find(auth => authorizationHeader.includes(auth));
	  
		  if (!matchedAuthType) {
			authType = "noAuth"; // If no match found, set authType to "no auth"
		  } else {
			if(matchedAuthType==="Basic"){
			  authType = "basicAuth"; 
        token = authorizationHeader.split(" ")[1]; // Base64 string
			}
			if(matchedAuthType==="Bearer")
			authType = "bearerToken"; // If a match is found, set it to the matched type
      token = authorizationHeader.split(" ")[1]; // Bearer token string
		  }
		} else {
		  authType = "noAuth"; // No Authorization header found
		}
	  
		
    console.log("Authorization Type:", authType);
    console.log("Authorization Token:", token);
    const updatedForms = [...loadTestRestApiFormState];

    // Assuming you're updating the first object in the array
    updatedForms[index] = {
      ...updatedForms[index], // Spread the existing object to retain its properties
      bodyStaticValue: parsedData?.formData ? JSON.stringify(parsedData?.formData) : "",
      apiPath: parsedData.url,
      httpMethod: parsedData.method.toLocaleLowerCase(),
      headerValues: Object.entries(parsedData.headers).map(([key, value]) => ({ key, value })),
      asyncAuthType: authType,
    };
    
    // Update the state with the modified array
    setLoadTestRestApiFormState(updatedForms);
		 setAuthType(authType)
		 handleCloseDialog
		 setCurlInput("")
     setForWhichIndexCurlIsOpened()
		 return parsedData; // Use this for further processing or displaying
	   
		
		} catch (error) {
		  console.error("Error parsing curl:", error);
		  return null;
		}
	  };



  const parseJSONKeys = (jsonString) => {
    try {
      const jsonObject = JSON.parse(jsonString);
      const keys = new Set();

      const extractKeys = (obj, parentKey = '') => {
        if (Array.isArray(obj)) {
          obj.forEach((item) => {
            if (typeof item === 'object' && item !== null) {
              extractKeys(item, parentKey);
            }
          });
        } else if (typeof obj === 'object' && obj !== null) {
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              const fullKey = parentKey ? `${parentKey}.${key}` : key;

              if (typeof obj[key] === 'object' && obj[key] !== null) {
                extractKeys(obj[key], fullKey);
              } else {
                keys.add(fullKey); 
              }
            }
          }
        }
      };

      extractKeys(jsonObject);
      return Array.from(keys);

    } catch (error) {
      console.error('Error parsing JSON:', error.message); 
      return [];
    }
  };  

  const parseGETParams = (fields) => {
    try {
      return fields.filter(e => e.key != "").map(k => k.key)
    }
    catch (error) {
      console.log(error);
      return []
    }
  }

  const handleTypeChange = (key, value) => {
    console.log("Key:", key, "Value:", value); // Debugging: check if the function is triggered
    setKeyTypes((prevKeyTypes) => ({
      ...prevKeyTypes,  // Copy previous state
      [key]: value      // Update the key with the new value
    }));
  };

  useEffect(() => {
    console.log("Updated keyTypes:", keyTypes); // Debugging: check if state is updated
  }, [keyTypes]);


  const handleApiResponse = (response) => {
    console.log(response);
    try {
      switch (response.status) {
        case 'success':
          toast({ description: response.message, variant: 'success' });
          navigate('/load')
          break;
        case 'error':
          switch (response.code) {
            case 404:
              toast({ description: response.message, variant: 'destructive' });
              break;
            case 400:
              toast({ title: "Warning", description: "You don't have any activated or valid subscription", variant: 'destructive' });
              break;
            case 500:
              toast({ description: response.message, variant: 'destructive' });
              break;
            default:
              toast({ description: 'An unknown error occurred.', variant: 'destructive' });
          }
          break;
        default:
          toast({ description: 'Unexpected response from the server.', variant: 'destructive' });
      }
    } catch (error) {
      console.error('runTest API call  failed:', error);
      toast({ description: 'Some Error Occurred.',variant: 'destructive',
        title: 'Error',});
    }
  };

  const runTest = async () => {
    if(!basicInfo.environment)
    {
      toast({ description: 'Select Environment', variant: 'destructive',
        title: 'Error',});
      return
    }
    if(!basicInfo.testName || !basicInfo.noOfUsers)
    {
      toast({ description: 'Fill the Required Fields', variant: 'destructive',
        title: 'Error', });
      return
    }

    const invalidIndex = loadTestRestApiFormState.findIndex(form => !form.isValidated);

    if (invalidIndex !== -1) {
      toast({
        description: `Form of Rest Api ${invalidIndex + 1} is not validated.`,
        variant: 'destructive',
        title: 'Error',
      });
      return;
    }


    const newPayload={
      user_id:user?.user_id,
      test_name:basicInfo.testName ,
      subscription_id: user?.activated_subscription_id,
      object_id: user?.object_id,
      virtual_user: basicInfo?.noOfUsers,
      enterprise_id: user?.enterprise_id,
      testObjects:payloadData ,
      environment:basicInfo?.environment
    }
    
    try {
      handleClose()
      setLoader(true);
      const loadTestRes = await loadTest(newPayload);
      setLoader(false);
      handleApiResponse(loadTestRes.data);
      // navigate('/load')
    } catch (error) {
      setLoader(false);
      console.error('runTest API call  failed:', error);
      toast({ description: 'Some Error Occurred.', variant: 'destructive' });
    }
  }

  const handleMethodChange = (newMethod) => {
    setFormState((prevState) => ({
      ...prevState,
      httpMethod: newMethod,
    }));
  };

  const handleEnvironmentChange = (newEnv) => {
    setBasicInfo((prevState) => ({
      ...prevState,
      environment: newEnv,
    }));
  };

  useEffect(() => {
    fetchEnvNames();
  }, []);

  const fetchEnvNames = async () => {
    try {
      setLoader(true);
      const response = await getEnvironmentNames({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id
      });
      setEnvNames(response.data.data.Environment_list.filter(i => i))
      setLoader(false);

    } catch (error) {
      setEnvNames([])
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch environment names'
      });
    }
  };


  const [openAccordion, setOpenAccordion] = useState('accordion-1'); // Default open item

  const handleToggle = (value) => {
    setOpenAccordion(openAccordion === value ? null : value); // Toggle open/close behavior
  };

  const handleRemoveForm = (index,apiId) => {
  

      setLoadTestRestApiFormState((prevData) => prevData.filter((_, i) => i !== index));
      setPayloadData((prevPayload) => prevPayload.filter((item) => item.apiId !== apiId));
    

  };

  return (
    <main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 w-full ">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
      
        {/*<div className="grid gap-4 py-4">*/}
        {/*  <div className="grid grid-cols-4 items-center gap-4">*/}
        <Form
          onSubmit={onSubmit}
          initialValues={
            formState
          }
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit,form, values }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 w-full">
                  <div className=' w-full justify-between items-center  flex ' >
                  {envNames.length > 0 && (
                    <div className="flex items-center w-full pt-2 gap-2">
                      <div className="flex-1 flex gap-4 flex-row font-semibold text-lg">
                        {/* <label htmlFor="environment" className="text-base">
                          Select Environment
                        </label> */}
                        <Field   name="environment"
                          component="select" value={basicInfo?.environment}>
    {({ input }) => (
      <div>
        <select
          {...input}
          value={basicInfo?.environment} // Set the value of the select element to httpMethod state
          onChange={(event) => handleEnvironmentChange(event.target.value)}
                        
          className=" bg-gray-700 border border-gray-600 text-white p-1 rounded-md flex-1"
          >
          <option value="">Choose Environment</option>
                          {envNames.map((envObj) => (
                            <option key={envObj} value={envObj}>
                              {envObj}
                            </option>
                          ))}
        </select>
      </div>
    )}
  </Field>
                      </div>
                      <div className='flex-1 flex items-center justify-end my-4 ' >
       <Button type="button" className=" text-white h-10 w-60  text-base font-medium"
              onClick={() => runTest()}>
              Run Test
            </Button>
       </div>
                    </div>
                  )}
    <div>
          
  
            
          </div>

                  </div>

                  <div className="flex w-full pt-2 gap-2 ">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="testName  " className={`mb-4 `}>
                        Test Name *
                      </Label>
                      <Field
  className="w-full bg-[#161A27] mt-2"
  id="testName"
  type="text"
  name="testName"
  validate={composeValidators(required)}
>
  {({ input, meta }) => (
    <div>
      <input
        {...input} // Spread all input props from Field
        value={basicInfo.testName} // Controlled value
        onChange={(e) => {
          setBasicInfo((prev) => ({ ...prev, testName: e.target.value }));
          setFormState((prev)=>({...prev,testName: e.target.value}))
        }}
        placeholder="Write test name like 'Load Test 1', etc."
        className="block w-full px-4 py-2 text-white rounded-md appearance-none focus:outline-none peer bg-[#161A27] border-[#747474] border"
        />
      {meta.error && meta.touched && <span className="text-red-500">{meta.error}</span>}
    </div>
  )}
</Field>

                    </div>

                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="noOfUsers" className={`mb-4`}>
                        Select the No of virtual Users *
                      </Label>
                      {/* <Field name="noOfUsers">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              type="text"
                              value={input.value !== undefined ? input.value : ''} // Ensure correct value
                              onChange={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (!isNaN(value) && value >= 1 && value <= 500) {
                                  input.onChange(value);
                                } else if (e.target.value === '') {
                                  input.onChange('');
                                }
                              }}
                              className="bg-[#161A27]"
                              // placeholder={'Enter the number of Virtual Users <=500'}
                            />
                            <Slider
                              value={[input.value !== undefined ? input.value : 20]}
                              onValueChange={(newValue) => {
                                input.onChange(newValue[0]);
                              }}
                              className="h-0 bg-background"
                              max={500}
                              min={1}
                              step={1}
                            />
                            {meta.error && meta.touched && (
                              <span className="text-red-500 text-xs italic align-text-top">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field> */}
                      <Field
  name="noOfUsers"
  validate={composeValidators(required)}
  
>
  {({ input, meta }) => (
    <div>
      <input
        {...input} // Spread all input props from Field
        value={basicInfo.noOfUsers} // Controlled value
        onChange={(e) => {
          setBasicInfo((prev) => ({ ...prev, noOfUsers: e.target.value }));
          setFormState((prev)=>({...prev,noOfUsers: e.target.value}))
        }}
        placeholder="Enter the number of virtual users (1-500)"
        className="block w-full px-4 py-2 placeholder:text-lg text-lg text-white rounded-md appearance-none focus:outline-none peer bg-[#161A27] border-[#747474] border"
          id="noOfUsers"
        type="text"
      />
      {meta.error && meta.touched && <span className="text-red-500">{meta.error}</span>}
    </div>
  )}
</Field>

                    </div>
                  </div>
                  <div className="flex items-center space-x-4 my-4">
                                <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  <Button type="button"
							 onClick={handleShowForm} 
							size="lg" 
						 variant="ghost"
                className="text-white h-10 w-56 text-base  bg-[#222938] hover:bg-[#222938] border-[#444444] border-2 hover:text-[#FFFFFF] flex items-center gap-2 font-bold"
							>
						
						<span className="sr-only sm:not-sr-only text-white  "><Plus/> </span>Add Rest APIs
				
	</Button>
                              </div>
                  {loadTestRestApiFormState.map((form, index) => (
                    <div key={`restApiForm-${index+1}`} className="mb-4">
                    <Card className="bg-[#1D2233]">
                      <CardContent className="p-4">
                  <Accordion key={index}  type="single" collapsible value={openAccordion}  >
                      <AccordionItem value={`accordion-${index+1}`} >
                        <AccordionTrigger  onClick={() => handleToggle(`accordion-${index+1}`)}   className="mb-4"  >
                        <div className="ml-2 text-2xl font-bold flex justify-between items-center w-full " >
                            <h2>Rest API Details {index+1}</h2> 
                           
                          </div>
                        </AccordionTrigger>
                        <AccordionContent   >
<div className="grid gap-4 w-full" >
<div className=' flex w-full items-center  justify-end ' >

                         
                          <Button type="button"
							 onClick={()=>handleRemoveForm(index,form?.apiId)} 
							size="lg" 
						 variant="destructive"
                className="text-white h-10 w-32 text-base mr-6   flex items-center gap-2 "
							>
						
					
                                                    <span className="sr-only sm:not-sr-only text-white">Delete</span>
                                               
				
	</Button>
                            
<Button type="button"
							 onClick={()=>handleOpenDialog(index)} 
							size="lg" 
						//  variant="ghost"
                // className="text-white h-10 w-60 text-base  bg-[#9747FF] hover:bg-[#222938] border-[#444444] border-2 hover:text-[#FFFFFF] flex items-center gap-2 font-bold"
                className="text-white h-10 w-60 text-base  bg-[#9747FF]   flex items-center gap-2 font-bold"
						
            >
						
						<span className="sr-only sm:not-sr-only text-white  ">Import From Curl</span>
				
	</Button>
</div>
                  <div>
                    <div className="flex items-center space-x-4 font-semibold text-xl pt-2">
                      <Label htmlFor="testName" className="text-lg font-bold">
                        API Detail
                      </Label>
                      <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                    </div>
                    <div className="flex items-center space-x-4">
  <label htmlFor={`httpMethod-${index}`} className="text-base">
    HTTP Methods
  </label>
  <Field name={`httpMethod-${index}`} component="select" value={form.httpMethod}>
    {({ input }) => (
      <div>
        <select
          {...input}
          value={form.httpMethod} // Set the value of the select element to httpMethod state
          onChange={(e) => {
            input.onChange(e);
            handleChange(index, 'httpMethod', e.target.value);
          }}
          className="border border-[#444444] bg-[#161A27] rounded-sm p-1"
        >
          <option value="" disabled>
            Select Method
          </option>
          {LOAD_TEST_METHODS.map((methodObj) => (
            <option key={methodObj.method} value={methodObj.method}>
              {methodObj.label}
            </option>
          ))}
        </select>
      </div>
    )}
  </Field>
</div>

                  </div>

                 
                  <div className="relative top-4 ">
                  <Label htmlFor={`apiPath-${index}`} className="">
                  API Path or Endpoint Path *
                      </Label>
  <Field
    id={`apiPath-${index}`}
    type="text"
    name={`apiPath-${index}`}
    validate={composeValidators(required)}
  >
    {({ input }) => (
      <>
        <input
          {...input}
          type="text"
          className="block w-full px-4 py-2 mt-1 mb-4 text-white rounded-md appearance-none focus:outline-none peer bg-[#161A27] border-[#747474] border"
          placeholder="Enter API Path ...."
          value={form.apiPath}
          onChange={ (event) =>
            handleChange(index,'apiPath',event.target.value)}
        />
        {/* <label
          htmlFor={`apiPath-${index}`}
          className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
        >
          API Path or Endpoint Path *
        </label> */}
      </>
    )}
  </Field>
</div>

               

                  {/* Api Auth Section  */}
                  <div className="flex items-center space-x-4 pt-4 text-lg font-bold">
                    <label htmlFor="testName" className="text-lg font-bold">
                      API Authorization
                    </label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>
                  <label htmlFor={`authType-${index}`} className="text-base font-semibold ">
                    Authorization Type
                  </label>

                  <div className="flex flex-row w-full space-x-4 ">
                    <div className="flex flex-col space-y-4 w-[25%]">
                      <Field name={`authType-${index}`} component="select" value={form.authType}>
                        {({ input }) => (
                          <div className="w-full flex flex-col">
                            <select
                              {...input}
                              value={form.authType} // Set the value of the select element to authType state
                              onChange={(e) => {
                                input.onChange(e);
                                handleChange(index,'authType',e.target.value)   }}
                              className="w-[95%] border border-[#444444] bg-[#161A27] rounded-sm p-1">
                              <option value="" disabled>
                                Select authorization type
                              </option>
                              {AUTHORIZATION_TYPES.map((type) => (
                                <option key={type.value} value={type.value}>
                                  {type.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </Field>

                      <p className="text-base text-[#A1A1AA] font-normal manrope-fontCss">
                        {form.authType === 'noAuth' && (
                          <>
                            The authorization header will be automatically generated when you send
                            the request. Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                            authorization.
                          </>
                        )}
                        {form.authType === 'Bearer' && (
                          <>
                            OAuth 2.0 requires you to provide an access token for authorization.
                            Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                            authorization.
                          </>
                        )}
                        {form.authType === 'basicAuth' && (
                          <>
                            Basic Authentication requires a username and password. Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                            authorization.
                          </>
                        )}
                        {form.authType === 'jwtbearer' && (
                          <div className="flex flex-col space-y-4 w-full">
                            <div className="">
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">
                                JWT Bearer Token
                              </span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-2 w-full">
                              <p className="w-[65%]">Add JWT Token to </p>

                              <span className="-mt-1">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                              </span>
                            </div>
                          </div>
                        )}
                        {form.authType === 'digitalauth' && (
                          <>
                            <div className="text-base flex flex-col space-y-4">
                              <div>
                                Basic Authentication requires a username and password. Learn more
                                about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">JWT Bearer</span>{' '}
                                authorization.
                              </div>
                              <div>
                                By default, Scale Secure will extract values from the received
                                response, add it to the request, and retry it. Do you want to
                                disable this?
                              </div>

                              <div className="flex items-center space-x-4">
                                <Checkbox />
                                <p>Yes, disable retrying the request</p>
                              </div>
                            </div>
                          </>
                        )}

                        {form.authType === 'oauth1' && (
                          <div className="flex flex-col space-y-4">
                            <div>
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">OAuth 1.0</span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-2">
                              <p className="w-[65%]">Add Auth Data to</p>

                              <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <p>
                                  Postman will automatically choose between body and URL based on
                                  the request method.
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                        {form.authType === 'oauth2' && (
                          <div className="flex flex-col space-y-4">
                            <div>
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">OAuth 2.0</span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-4">
                              <p>Add Auth Data to</p>

                              <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <p>
                                  Postman will automatically choose between body and URL based on
                                  the request method.
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                        {form.authType === 'hawk' && (
                          <>
                            Basic Authentication requires a username and password. Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">OAuth 1.0</span>{' '}
                            authorization.
                          </>
                        )}
                        {form.authType === 'awssign' && (
                          <div className="flex flex-col space-y-4">
                            <div>
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">OAuth 1.0</span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-4">
                              <p>Add Auth Data to</p>

                              <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <p>
                                  Postman will automatically choose between body and URL based on
                                  the request method.
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                        {form.authType === 'nltm' && (
                          <>
                            <div className="text-base flex flex-col space-y-4">
                              <div>
                                Basic Authentication requires a username and password. Learn more
                                about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">JWT Bearer</span>{' '}
                                authorization.
                              </div>
                              <div>
                                By default, Scale Secure will extract values from the received
                                response, add it to the request, and retry it. Do you want to
                                disable this?
                              </div>

                              <div className="flex items-center space-x-4">
                                <Checkbox />
                                <p>Yes, disable retrying the request</p>
                              </div>
                            </div>
                          </>
                        )}

                        {!form.authType && (
                          <>
                            The authorization header will be automatically generated when you send
                            the request. Select an authorization type to see more details.
                          </>
                        )}
                      </p>
                    </div>

                    <div className="h-full w-[1px] bg-[#6F6F6F] inline-block"></div>

                    <div className="flex flex-col w-[80%]">
                      <Card className="w-full h-auto p-3 border border-[#747474]">
                        {form.authType === LOAD_TEST_AUTH_TYPE[0].value && (
                          <div className="flex flex-col gap-4 items-center">
                            <p className="text-sm text-[#A1A1AA]">
                              This request does not use any authorization. Learn more about
                              authorization.
                            </p>
                          </div>
                        )}
                        {form.authType === LOAD_TEST_AUTH_TYPE[1].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                              <div className="grid gap-2">
                                <div className="relative">
                                  <Field
                                    id={`username-${index}`}
                                    type="text"
                                    name={`username-${index}`}
                                    // validate={composeValidators(required)}
                                    >
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-full px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={form.username}

                                        onChange={(e) =>
                                          handleChange(index,"username",e.target.value)
                                        }
                                        />
                                        <label
                                          htmlFor={`username-${index}`}
                                          className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                          Username *
                                        </label>
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="relative">
                                <Field
                                  id={`password-${index}`}
                                  type="password"
                                  name={`password-${index}`}
                                  // validate={composeValidators(required)}
                                  >
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="password"
                                        className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                        placeholder=" "
                                        value={form.password}
                                      onChange={(e) =>
                                        handleChange(index,"password",e.target.value)
                                      }
                                      />
                                      <label
                                        htmlFor={`password-${index}`}
                                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                        Password *
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        )}
                        {form.authType === LOAD_TEST_AUTH_TYPE[2].value && (
                          <div className="grid grid-cols-1 pt-2 gap-2">
                            <div className="grid gap-4 ">
                              <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                                <p className="flex justify-center">
                                  Note: These parameters hold sensitive data. To keep this data
                                  secure while working in a collaborative environment, we recommend
                                  using variables. Learn more about{' '}
                                  <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                  here.
                                </p>
                              </div>
                              <div className="relative ">
                                <Field
                                  id={`bearer-${index}`}
                                  type="textarea"
                                  name={`bearer-${index}`}
                                  // validate={composeValidators(required)}
                                  >
                                  {({ input }) => (
                                    <>
                                      <textarea
                                        {...input}
                                        className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                        placeholder=" "
                                      value={form.bearer}
                                      onChange={(e) =>
                                        handleChange(index,"bearer",e.target.value)
                                      }
                                      />
                                      <label
                                        htmlFor={`bearer-${index}`}
                                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                        Bearer Token *
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* {authType === LOAD_TEST_AUTH_TYPE[3].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="algorithm" className="flex items-center gap-1">
                                    <p className="text-base">Algorithm</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>

                                  <Field
                                    id="algorithm"
                                    type="text"
                                    name="algorithm"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.algorithm}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="secret" className="flex items-center gap-1">
                                  <p className="text-base">Secret</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>

                                <Field
                                  id="secret"
                                  type="text"
                                  name="secret"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="text"
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                        placeholder=" "
                                        value={values.secret}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="flex items-center ml-[30%]">
                                <Checkbox className="mr-2" />
                                <p>Secret Base64 encoded</p>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="payload" className="flex items-center gap-1">
                                  <p className="text-base">Payload</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>
                                <Field
                                  id="payload"
                                  component="textarea"
                                  name="payload"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <textarea
                                        {...input}
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-32"
                                        placeholder=" "
                                        value={values.payload}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                              <p className="text-xl text-white ">Advanced Configrations</p>
                              <p className="text-sm text-[#A1A1AA]">
                                Scale Secure auto-generates default values for some of these fields
                                unless a value is specified.
                              </p>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="headerprefix" className="flex items-center gap-1">
                                  <p className="text-base">Request Header Prefix</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>
                                <Field
                                  id="headerprefix"
                                  type="text"
                                  name="headerprefix"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="text"
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] "
                                        placeholder=" "
                                        value={values.headerprefix}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="jwtheader" className="flex items-center gap-1">
                                  <p className="text-base">JWT Headers</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>
                                <Field
                                  id="jwtheaders"
                                  component="textarea"
                                  name="jwtheaders"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <textarea
                                        {...input}
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-32"
                                        placeholder=" "
                                        value={values.jwtheaders}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                              <p className="text-[#A1A1AA] text-base ml-[30%]">
                                Headers specific to the algorithm are added automatically.
                              </p>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[4].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="username" className="flex items-center gap-1">
                                    <p className="text-base">Username</p>
                                  </Label>
                                  <Field
                                    id="username"
                                    type="text"
                                    name="username"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.username}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="password" className="flex items-center gap-1">
                                    <p className="text-base">Password</p>
                                  </Label>
                                  <Field
                                    id="password"
                                    type="password"
                                    name="password"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="password"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.password}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="password" className="flex items-center gap-1">
                                    <p className="text-base">Realm</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="realm"
                                    type="text"
                                    name="realm"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="testrealm@example.com "
                                          value={values.realm}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nonce" className="flex items-center gap-1">
                                    <p className="text-base">Nonce</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nonce"
                                    type="text"
                                    name="nonce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Nonce"
                                          value={values.nonce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="algorithm" className="flex items-center gap-1">
                                    <p className="text-base">Algorithm:</p>
                                  </Label>
                                  <Field name="algorithm" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Select an algorithm
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="qop" className="flex items-center gap-1">
                                    <p className="text-base">QOP</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="qop"
                                    type="text"
                                    name="qop"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: auth-int"
                                          value={values.qop}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nouncecount" className="flex items-center gap-1">
                                    <p className="text-base">Nounce Count</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nouncecount"
                                    type="text"
                                    name="nouncecount"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: 0000000001"
                                          value={values.nouncecount}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientnounce" className="flex items-center gap-1">
                                    <p className="text-base">Client Nounce </p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="clientnounce"
                                    type="text"
                                    name="clientnounce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: 0a4f12a3d"
                                          value={values.clientnounce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="opaque" className="flex items-center gap-1">
                                    <p className="text-base">OPaque</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="opaque"
                                    type="text"
                                    name="opaque"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Opaque"
                                          value={values.opaque}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[5].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label
                                    htmlFor="signature method"
                                    className="flex items-center gap-1">
                                    <p className="text-base">Signature Method:</p>
                                  </Label>
                                  <Field name="algorithm" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Signature Method:
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="consumerkey" className="flex items-center gap-1">
                                    <p className="text-base">Consumer Key</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="consumerkey"
                                    type="text"
                                    name="consumerkey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="consumer key "
                                          value={values.consumerkey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="accesstoken" className="flex items-center gap-1">
                                    <p className="text-base">Access Token</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="accesstoken"
                                    type="text"
                                    name="accesstoken"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="access token "
                                          value={values.accesstoken}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="tokensecret" className="flex items-center gap-1">
                                    <p className="text-base">Token Secret</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="tokensecret"
                                    type="text"
                                    name="tokensecret"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Secret Token "
                                          value={values.tokensecret}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="callbackurl" className="flex items-center gap-1">
                                    <p className="text-base">CallBack Url</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>

                                  <Field name="callbackurl" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          CallBack Url Methods
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="verifier" className="flex items-center gap-1">
                                    <p className="text-base">Verifer</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="verifier"
                                    type="text"
                                    name="verifier"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Verifier"
                                          value={values.verifier}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="timestamp" className="flex items-center gap-1">
                                    <p className="text-base">TimeStamp</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="timestamp"
                                    type="text"
                                    name="timestamp"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Timestamp"
                                          value={values.timestamp}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nounce" className="flex items-center gap-1">
                                    <p className="text-base">Nounce</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nounce"
                                    type="text"
                                    name="nounce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Nounce"
                                          value={values.nounce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="version" className="flex items-center gap-1">
                                    <p className="text-base">Version</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="version"
                                    type="text"
                                    name="version"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="version"
                                          value={values.version}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="realm" className="flex items-center gap-1">
                                    <p className="text-base">Realm</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="realm"
                                    type="text"
                                    name="realm"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="realm"
                                          value={values.realm}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <hr className="h-px bg-[#6F6F6F] border-0 " />
                              <div className="flex items-center text-muted-foreground text-base manrope-fontCss">
                                <Checkbox className="mr-2" />
                                <p>Include Body Hash</p>
                              </div>
                              <div className="flex items-center text-muted-foreground text-base manrope-fontCss">
                                <Checkbox className="mr-2" />
                                <p>Add Empty Parameters to Signature</p>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[6].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div>
                                <p className="text-xl manrope-fontCss font-semibold">
                                  Current Token
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="token1" className="flex items-center gap-1">
                                    <p className="text-base font-medium">Token</p>
                                  </Label>
                                  <Field name="token1" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Available Token
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                                <div className="flex ml-[30%] w-full">
                                  <Field
                                    id="token"
                                    type="text"
                                    name="token"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Token"
                                          value={values.token}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="headerprefix" className="flex items-center gap-1">
                                    <p className="text-base">Header Prefix</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="headerprefix"
                                    type="text"
                                    name="headerprefix"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Bearer"
                                          value={values.headerprefix}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="flex flex-row justify-between w-full">
                                <div className="flex flex-col space-y-1">
                                  <p className="text-base font-semibold">Auto-Refresh Token</p>
                                  <p className="text-sm font-normal text-muted-foreground">
                                    Your expired token will be auto-refreshed before sending a
                                    request.
                                  </p>
                                </div>
                                <div className="p-2">
                                  <Switch
                                    id="auto-refresh-token"
                                    className=" w-12 h-6"
                                    value={isAutoRefreshEnabled}
                                    onCheckedChange={() =>
                                      setIsAutoRefreshEnabled(!isAutoRefreshEnabled)
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="h-px bg-[#6F6F6F] border-0 w-full" />

                              <div className="flex flex-row justify-between w-full">
                                <div className="flex flex-col space-y-1">
                                  <p className="text-base font-semibold">Share Token</p>
                                  <p className="text-sm font-normal text-muted-foreground">
                                    This will allow anyone with access to this request to view and
                                    use it.
                                  </p>
                                </div>
                                <div className="p-2">
                                  <Switch
                                    id="auto-refresh-token"
                                    className=" w-12 h-6"
                                    value={isAutoRefreshEnabled}
                                    onCheckedChange={() =>
                                      setIsAutoRefreshEnabled(!isAutoRefreshEnabled)
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="h-px bg-[#6F6F6F] border-0 w-full" />
                              <div>
                                <p className="text-xl font-semibold">Configure New Token</p>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="tokenname" className="flex items-center gap-1">
                                    <p className="text-base">Token Name</p>
                                  </Label>
                                  <Field
                                    id="tokenname"
                                    type="text"
                                    name="tokenname"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Token Name"
                                          value={values.tokenname}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="granttype" className="flex items-center gap-1">
                                    <p className="text-base">Grant Type</p>
                                  </Label>
                                  <Field name="granttype" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] text-muted-foreground rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Authorization code
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="callbackurl" className="flex items-center gap-1">
                                    <p className="text-base">CallBack URL</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="callbackurl"
                                    type="text"
                                    name="callbackurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Callbackurl"
                                          value={values.callbackurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="authurl" className="flex items-center gap-1">
                                    <p className="text-base">Auth URL</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="authurl"
                                    type="text"
                                    name="authurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="oauth,.pstmn.io/v1/browser-callback"
                                          value={values.authurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label
                                    htmlFor="accesstokenurl"
                                    className="flex items-center gap-1">
                                    <p className="text-base">Access Token URL</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="accesstokenurl"
                                    type="text"
                                    name="accesstokenurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="oauth,.pstmn.io/v1/browser-callback"
                                          value={values.accesstokenurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientid" className="flex items-center gap-1">
                                    <p className="text-base">Client ID</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="clientid"
                                    type="text"
                                    name="clientid"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Client ID"
                                          value={values.clientid}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientsecret" className="flex items-center gap-1">
                                    <p className="text-base">Client Secret</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="clientsecret"
                                    type="text"
                                    name="clientsecret"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Client Secret"
                                          value={values.clientsecret}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="scope" className="flex items-center gap-1">
                                    <p className="text-base">Scope</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="scope"
                                    type="text"
                                    name="scope"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="scope"
                                          value={values.scope}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="state" className="flex items-center gap-1">
                                    <p className="text-base">State</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="state"
                                    type="text"
                                    name="state"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="State"
                                          value={values.state}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientauth" className="flex items-center gap-1">
                                    <p className="text-base">Client Authentication</p>
                                  </Label>
                                  <Field name="clientauth" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] text-muted-foreground rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Send as Basic Auth Header
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label
                                    htmlFor="refreshtokenurl"
                                    className="flex items-center gap-1">
                                    <p className="text-base">Refresh Token Url</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="refreshtokenurl"
                                    type="text"
                                    name="refreshtokenurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="oauth,.pstmn.io/v1/browser-callback"
                                          value={values.refreshtokenurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="manrope-fontCss text-white bg-[#161A27] w-full space-y-4">
                                <div className="space-y-4 space-x-4 justify-between flex flex-row">
                                  <div>
                                    <h2 className="text-base font-semibold flex items-center gap-2">
                                      Auth Request
                                      <div className="flex items-center">
                                        <QuestionMarkCircledIcon
                                          color="#616161"
                                          className=""
                                          width={19}
                                          height={19}
                                        />
                                      </div>
                                    </h2>
                                  </div>
                                  <div className="border border-[#333333] rounded-md overflow-hidden w-[70%]">
                                    <table className="w-full border-solid">
                                      <thead>
                                        <tr className="bg-[#161A27] border border-[#333333] text-left">
                                          <th className="p-3 text-base font-medium border-r border-[#333333]"></th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Key
                                          </th>
                                          <th className="p-3 text-base font-medium">Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]"></td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Key Parameter"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Value"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="space-y-4 space-x-4 justify-between flex flex-row">
                                  <div>
                                    <h2 className="text-base font-semibold flex items-center gap-2">
                                      Token Request
                                      <div className="flex items-center">
                                        <QuestionMarkCircledIcon
                                          color="#616161"
                                          className=""
                                          width={19}
                                          height={19}
                                        />
                                      </div>
                                    </h2>
                                  </div>
                                  <div className="border border-[#333333] rounded-md overflow-hidden w-[70%]">
                                    <table className="w-full border-solid">
                                      <thead>
                                        <tr className="bg-[#161A27] border border-[#333333] text-left">
                                          <th className="p-3 text-base font-medium border-r border-[#333333]"></th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Key
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Value
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Send In
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]"></td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Key Parameter"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Value"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="sendIn"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="space-y-4 space-x-4 justify-between flex flex-row">
                                  <div>
                                    <h2 className="text-base font-semibold flex items-center gap-2">
                                      Token Request
                                      <div className="flex items-center">
                                        <QuestionMarkCircledIcon
                                          color="#616161"
                                          className=""
                                          width={19}
                                          height={19}
                                        />
                                      </div>
                                    </h2>
                                  </div>
                                  <div className="border border-[#333333] rounded-md overflow-hidden w-[70%]">
                                    <table className="w-full border-solid">
                                      <thead>
                                        <tr className="bg-[#161A27] border border-[#333333] text-left">
                                          <th className="p-3 text-base font-medium border-r border-[#333333]"></th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Key
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Value
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Send In
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]"></td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Key Parameter"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Value"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="sendIn"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <button className="bg-[#9747FF] text-white py-2 px-4 rounded-md hover:bg-[#8A3DFF] transition duration-300">
                                  Get New Access Token
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[7].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="hawkauthid" className="flex items-center gap-1">
                                    <p className="text-base">HAWK Auth ID:</p>
                                  </Label>
                                  <Field
                                    id="hawkauthid"
                                    type="text"
                                    name="hawkauthid"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="HMAC HSA1"
                                          value={values.hawkauthid}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="hawkauthkey" className="flex items-center gap-1">
                                    <p className="text-base">HAWK Auth Key:</p>
                                  </Label>
                                  <Field
                                    id="hawkauthkey"
                                    type="text"
                                    name="hawkauthkey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Hawk Auth Key"
                                          value={values.hawkauthkey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="algorithm" className="flex items-center gap-1">
                                    <p className="text-base">Algorithm:</p>
                                  </Label>
                                  <Field name="algorithm" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Select an algorithm
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="user" className="flex items-center gap-1">
                                    <p className="text-base">User</p>
                                  </Label>
                                  <Field
                                    id="user"
                                    type="text"
                                    name="user"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="User"
                                          value={values.user}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nounce" className="flex items-center gap-1">
                                    <p className="text-base">Nounce</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nounce"
                                    type="text"
                                    name="nounce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="nounce"
                                          value={values.nounce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="ext" className="flex items-center gap-1">
                                    <p className="text-base">Ext</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="ext"
                                    type="text"
                                    name="ext"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="ext"
                                          value={values.ext}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="app" className="flex items-center gap-1">
                                    <p className="text-base">App</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="app"
                                    type="text"
                                    name="app"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="app"
                                          value={values.app}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="dlg" className="flex items-center gap-1">
                                    <p className="text-base">Dlg</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="dlg"
                                    type="text"
                                    name="dlg"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="1.0"
                                          value={values.dlg}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="timestamp" className="flex items-center gap-1">
                                    <p className="text-base">Timestamp</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="timestamp"
                                    type="text"
                                    name="timestamp"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Timestamp"
                                          value={values.timestamp}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[8].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="accesskey" className="flex items-center gap-1">
                                    <p className="text-base">Access Key</p>
                                  </Label>
                                  <Field
                                    id="accesskey"
                                    type="text"
                                    name="accesskey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.accesskey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="secretkey" className="flex items-center gap-1">
                                    <p className="text-base">Secret Key</p>
                                  </Label>
                                  <Field
                                    id="secretkey"
                                    type="text"
                                    name="secretkey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.secretkey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="awsregion" className="flex items-center gap-1">
                                    <p className="text-base">AWS Region</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="awsregion"
                                    type="text"
                                    name="awsregion"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="testrealm@example.com"
                                          value={values.awsregion}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="servicename" className="flex items-center gap-1">
                                    <p className="text-base">Service Name</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="servicename"
                                    type="text"
                                    name="servicename"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="nonce"
                                          value={values.servicename}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="sessiontoken" className="flex items-center gap-1">
                                    <p className="text-base">Session Token</p>
                                  </Label>
                                  <Field name="sessiontoken" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Select a session token
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="qop" className="flex items-center gap-1">
                                    <p className="text-base">QOP</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="qop"
                                    type="text"
                                    name="qop"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: auth-int"
                                          value={values.qop}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[9].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="username" className="flex items-center gap-1">
                                    <p className="text-base">Username</p>
                                  </Label>
                                  <Field
                                    id="username"
                                    type="text"
                                    name="username"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.username}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="password" className="flex items-center gap-1">
                                    <p className="text-base">Password</p>
                                  </Label>
                                  <Field
                                    id="password"
                                    type="text"
                                    name="password"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="password"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.password}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="domain" className="flex items-center gap-1">
                                    <p className="text-base">Domain</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="domain"
                                    type="email"
                                    name="domain"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="testrealm@example.com"
                                          value={values.domain}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="workstation" className="flex items-center gap-1">
                                    <p className="text-base">Workstation</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="workstation"
                                    type="text"
                                    name="workstation"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="nonce"
                                          value={values.workstation}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                      </Card>
                    </div>
                  </div>

                  {/* Api Authorization  */}

                  <div className="flex items-center space-x-4 pt-4">
                    <Label htmlFor={`headerValues-${index}`} className="text-lg font-bold">
                      Headers
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                  <FieldArray name={`headerValues-${index}`}>
  {({ fields }) => {
    // Add default fields from `loadTestRestApiFormState` if necessary
    const headerValues = loadTestRestApiFormState[index].headerValues || [];

    return (
      <>
        {headerValues.map((header, fieldArrayIndex) => (
          <div key={fieldArrayIndex} className="grid grid-cols-2 items-center space-x-4 pb-2">
            <div className="flex flex-col col-span-1">
              {fieldArrayIndex === 0 && (
                <label className="text-lg font-semibold mb-2">Key</label>
              )}
              <Field
                name={`headerValues[${fieldArrayIndex}].key`}
                render={({ input }) => (
                  <Input
                    {...input}
                    placeholder="Key"
                    className="border p-2 rounded bg-[#161A27]"
                    value={header.key} // Binding to the value from loadTestRestApiFormState
                    onBlur={(e) =>
                      handleHeaderKeyValueBlurOrChange(index, fieldArrayIndex, 'key', e.target.value)
               }
                    onChange={(e) => {
                      input.onChange(e);
                      handleHeaderKeyValueBlurOrChange(index, fieldArrayIndex, 'key', e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-1">
              {fieldArrayIndex === 0 && (
                <label className="text-lg font-semibold mb-2">Value</label>
              )}
              <div className="flex items-center space-x-4">
                <Field
                  name={`headerValues[${fieldArrayIndex}].value`}
                  render={({ input }) => (
                    <Input
                      {...input}
                      placeholder="Value"
                      className="border p-2 rounded bg-[#161A27]"
                      value={header.value} // Binding to the value from loadTestRestApiFormState
                      onBlur={(e) =>
                        handleHeaderKeyValueBlurOrChange(index, fieldArrayIndex, 'key', e.target.value)
                 }
                      onChange={(e) => {
                        input.onChange(e);
                        handleHeaderKeyValueBlurOrChange(index, fieldArrayIndex, 'value', e.target.value);
                      }}
                    />
                  )}
                />
                {/* Add remove button for fields if necessary */}
                {headerValues.length > 1 && (
                  <Trash2
                    type="button"
                    onClick={() => {
                      fields.remove(fieldArrayIndex); // Update FieldArray
                      setLoadTestRestApiFormState((prev) => {
                        const updatedState = [...prev];
                        updatedState[index].headerValues = updatedState[index].headerValues.filter(
                          (_, idx) => idx !== fieldArrayIndex
                        );
                        return updatedState;
                      });
                    }}
                    className="focus:outline-none cursor-pointer"
                    size={18}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }}
</FieldArray>

                  </div>
                  <div className="flex items-center space-x-4 pt-2">
                    <Label htmlFor={`paramValues-${index}`} className="text-lg font-bold">
                      Parameters
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                  <FieldArray name={`paramValues-${index}`}>
  {({ fields }) => {
    // Add default fields from `loadTestRestApiFormState` if necessary
    const paramValues = loadTestRestApiFormState[index].paramValues || [];

    return (
      <>
        {paramValues.map((header, fieldArrayIndex) => (
          <div key={fieldArrayIndex} className="grid grid-cols-2 items-center space-x-4 pb-2">
            <div className="flex flex-col col-span-1">
              {fieldArrayIndex === 0 && (
                <label className="text-lg font-semibold mb-2">Key</label>
              )}
              <Field
                name={`paramValues[${fieldArrayIndex}].key`}
                render={({ input }) => (
                  <Input
                    {...input}
                    placeholder="Key"
                    className="border p-2 rounded bg-[#161A27]"
                    value={header.key} // Binding to the value from loadTestRestApiFormState
                    onBlur={(e) =>
                      handleParamsKeyValueBlurOrChange(index, fieldArrayIndex, 'key', e.target.value)
               }
                    onChange={(e) => {
                      input.onChange(e);
                      handleParamsKeyValueBlurOrChange(index, fieldArrayIndex, 'key', e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-1">
              {fieldArrayIndex === 0 && (
                <label className="text-lg font-semibold mb-2">Value</label>
              )}
              <div className="flex items-center space-x-4">
                <Field
                  name={`paramValues[${fieldArrayIndex}].value`}
                  render={({ input }) => (
                    <Input
                      {...input}
                      placeholder="Value"
                      className="border p-2 rounded bg-[#161A27]"
                      value={header.value} // Binding to the value from loadTestRestApiFormState
                      onBlur={(e) =>
                        handleParamsKeyValueBlurOrChange(index, fieldArrayIndex, 'key', e.target.value)
                 }
                      onChange={(e) => {
                        input.onChange(e);
                        handleParamsKeyValueBlurOrChange(index, fieldArrayIndex, 'value', e.target.value);
                      }}
                    />
                  )}
                />
                {/* Add remove button for fields if necessary */}
                {paramValues.length > 1 && (
                  <Trash2
                    type="button"
                    onClick={() => {
                      fields.remove(fieldArrayIndex); // Update FieldArray
                      setLoadTestRestApiFormState((prev) => {
                        const updatedState = [...prev];
                        updatedState[index].paramValues = updatedState[index].paramValues.filter(
                          (_, idx) => idx !== fieldArrayIndex
                        );
                        return updatedState;
                      });
                    }}
                    className="focus:outline-none cursor-pointer"
                    size={18}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }}
</FieldArray>
                  </div>
                  {(form.httpMethod === LOAD_TEST_METHODS[0].method) && (
                    <>
                      <div></div>
                      <Field name={`isBodyDynamic-${index}`} type="checkbox">
                        {({ input }) => (
                          <div className="flex items-center space-x-2 mt-2">
                            <input
                              type="checkbox"
                              {...input}
                              id={`isBodyDynamic-${index}`}
                              checked={form.isBodyDynamic}
                              onChange={(e) => {
                                input.onChange(e);
                                handleChange(index,"isBodyDynamic",e.target.checked)
                                if (e.target.checked) {
                                  const keys = parseGETParams(form.paramValues);
                                  updateParsedKeys(index, keys);
                                } else {
                                  updateParsedKeys(index, []);
                                }
                              }}
                            />
                            <Label htmlFor={`isBodyDynamic-${index}`}>Dynamic Params</Label>
                          </div>
                        )}
                      </Field>
                    </>
                  )}
                  {(form.httpMethod === LOAD_TEST_METHODS[1].method ||
                    form.httpMethod === LOAD_TEST_METHODS[3].method) && (
                      <>
                        <div className="flex items-center space-x-4 pt-4">
                          <Label htmlFor={`bodyStaticValue-${index}`}>Body</Label>
                          <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                        </div>
                        <Field name={`bodyStaticValue-${index}`}>
                          {({ input }) => (
                            <textarea
                              {...input}
                              className="rounded border px-2 bg-[#161A27] border-[#747474]"
                              placeholder="Enter JSON value"
                              value={form.bodyStaticValue}
                              onChange={(e)=>handleChange(index,"bodyStaticValue",e.target.value)}
                              rows={5}
                            />
                          )}
                        </Field>
                        <Field name={`isBodyDynamic-${index}`} id={`isBodyDynamic-${index}`} type="checkbox">
                          {({ input }) => (
                            <div className="flex items-center space-x-2 mt-2">
                              <input
                                type="checkbox"
                                {...input}
                                id={`isBodyDynamic-${index}`}
                                checked={form.isBodyDynamic}
                                onChange={(e) => {
                                  input.onChange(e);
                                  handleChange(index,"isBodyDynamic",e.target.checked)
                               
                                  if (e.target.checked) {
                                    const keys = parseJSONKeys(form.bodyStaticValue);
                                    updateParsedKeys(index, keys);
                                  } else {
                                    updateParsedKeys(index, []);
                                  }
                                }}
                              />
                              <Label htmlFor={`isBodyDynamic-${index}`}>Dynamic Body</Label>
                            </div>
                          )}
                        </Field>
                      </>
                    )}
                {/* </div> */}
                <div className="flex-row mt-4 gap-4 w-full mb-4">
                  {form.isBodyDynamic && parsedKeys[index].length > 0 && (
                    <div className="mt-4">
                      <h4 className="text-white mb-2">Parsed JSON Keys:</h4>
                      {/* <div className="space-y-2">
                        {parsedKeys.map((key, index) => (
                          <div key={index} className="grid grid-cols-2 gap-4 items-center pb-2">
                            <div className="flex flex-col col-span-1">
                              {index === 0 && (
                                <label className="text-lg font-semibold mb-2 text-white">Key</label>
                              )}
                              <span className="text-white p-2 rounded-md bg-gray-800">{key}</span>
                            </div>

                            <div className="flex flex-col col-span-1">
                              {index === 0 && (
                                <label className="text-lg font-semibold mb-2 text-white">Type</label>
                              )}
                              <select
                                className="bg-[#161A27] border border-gray-600 text-white p-2 rounded-md w-full"
                                value={keyTypes[key] || ''}
                                onChange={(e) => handleTypeChange(key, e.target.value)}
                              >
                                <option value="">Not-Dynamic</option>
                                <option value="email">Email</option>
                                <option value="string">String</option>
                                <option value="number">Number</option>
                                <option value="boolean">Boolean</option>
                                <option value="url">URL</option>
                                <option value="date">Date</option>
                                <option value="alphanumeric">Alphanumeric</option>
                              </select>
                            </div>
                          </div>
                        ))}
                      </div> */}
                      <div className="space-y-2">
                        {parsedKeys[index].map((key, index) => (
                          <div key={index} className="grid grid-cols-2 gap-4 items-center pb-2">
                            <div className="flex flex-col col-span-1">
                              {index === 0 && (
                                <label className="text-lg font-semibold mb-2 text-white">Key</label>
                              )}
                              <span className="text-white p-2 rounded-md bg-gray-700 break-all">{key}</span>
                            </div>

                            <div className="flex flex-col col-span-1">
                              {index === 0 && (
                                <label className="text-lg font-semibold mb-2 text-white">Type</label>
                              )}
                              <select
                                className="bg-[#161A27] border border-gray-600 text-white p-2 rounded-md w-full"
                                value={keyTypes[key] || ''}  // Controlled component: value comes from keyTypes
                                onChange={(e) => handleTypeChange(key, e.target.value)}  // Update state on change
                              >
                                <option value="">Select Type</option>
                                <option value="email">Email</option>
                                <option value="string">String</option>
                                {/* <option value="password">Password</option> */}
                                <option value="number">Number</option>
                                <option value="boolean">Boolean</option>
                                <option value="url">URL</option>
                                <option value="date">Date</option>
                                <option value="alphanumeric">Alphanumeric</option>
                              </select>
                            </div>
                          </div>
                        ))}
                      </div>


                    </div>
                  )}
                </div>
                <div className="flex flex-row mt-4 gap-4 w-full mb-4">
                  <div className="w-1/2 justify-start items-center">
                    {/* <Button
                      type="submit"
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium gap-1 border-[#444444] border">
                      <Plus />
                      New Test
                    </Button> */}
                  </div>
                  <div className="flex w-1/2 justify-end gap-4 ">
                  <Button
                  variant="ghost"
        type="button"  // Set to "button" so it doesn't submit the form
        onClick={() =>{ 

          setLoader(true)
                        form.restart()
                        form.change("isBodyDynamic",false)
                        form.change("bodyStaticValue","")
                        form.change("httpMethod",LOAD_TEST_METHODS[1].method)
                        form.change("environment","")
                        form.change("authType",LOAD_TEST_AUTH_TYPE[0].type)
                        form.change("bodyType",LOAD_TEST_BODY_TYPE[0].type)
                        form.change("headerValues",[{ key: '', value: '' }])
                        form.change("apiPath","")
                        form.change("testName","")
                        form.change("noOfUsers","")
                        form.change("paramValues",[{ key: '', value: '' }])
                        form.change("bodyDynamicValue",[{ name: '', type: '', isDynamic: true }])
                       setTimeout(() => {
                        setLoader(false)
                        navigate("/load")
                       }, 1000);

        }}  // Reset the form to its initial state
        className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border">
        Discard Changes
      </Button>
                    <Button type="button" onClick={()=>onSubmit(loadTestRestApiFormState,index)} className=" text-white h-10 w-60 text-base font-medium">
                      Validate
                    </Button>
                  </div>
                </div>
                </div>
                </AccordionContent>
                </AccordionItem>
                </Accordion>
                </CardContent>
                </Card>
                </div>
              
              ))}
                </div>
              </form>

            </>
          )}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" color="black">
              API Response
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} color="black" style={bodyStyle}>
              <pre>{renderResponse(apiResponse)}</pre>
            </Typography>
            <Button type="button" className=" text-white h-10 w-60 text-base font-medium"
              onClick={() => handleClose()}>
              OK
            </Button>
          </Box>

        </Modal>
        {/*  </div>*/}
        {/*</div>*/}
      </div>

          {/* Dialog Box For Curl Import  */}


      <CurlImportDialog
        isOpen={isCurlDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleParseCurl}
		curlInput={curlInput}
		setCurlInput={setCurlInput}
    forWhichIndexCurlIsOpened={forWhichIndexCurlIsOpened}
      />
    </main>
  );
};

export default withLoader(withUser(LoadTestForm));


import axios from 'axios';
import { createSubscription } from '../utils/util';

export const payment = async (plan_id, user, navigate, total_count,amount=0) => {
  try {
    const createOrderRes = await createSubscription({
      plan_id: plan_id,
      user_id: user.user_id,
      total_count: total_count
    });
    console.log('create order', createOrderRes,amount);
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: createOrderRes?.data?.subscription_id,
      currency:"INR",
      // order_id:createOrderRes?.data?.subscription_id,
      amount: amount * 100, // Convert to paise (if `amount` is in INR, multiply by 100)
      name: 'Merchant Name',
      description: 'Test Subscription',
      image: 'https://example.com/your_logo',
     
      // handler: function () {
      //   navigate('/');
      // },

      // callback_url: 'http://localhost:3001/v1/verify-payment',

   

handler: async function (response) {
  console.log("Payment Success:", response);

  try {
    const verifyRes = await axios.post(
      "http://localhost:3001/v1/verify-payment",
      response,
      { headers: { "Content-Type": "application/json" } }
    );

    console.log("Verification Response:", verifyRes.data);
  } catch (err) {
    console.error("Verification Error:", err);
  }
},


      // redirect: true,
      prefill: {

        name: '',
        email: '',
        contact: '9999999999'
      },
      notes: {
        address: 'Razorpay Corporate Office'
      },
      theme: {
        color: '#3399cc'
      }
    };
    console.log('subscription_id', createOrderRes.data.subscription_id,options);

    // window.Razorpay.subscriptions.fetch('sub_abcdef12345').then((subscription) => {
    //   console.log(subscription);
    // }).catch((error) => {
    //   console.error(error);
    // });


    if (!window.Razorpay) {
      console.error("Razorpay SDK not loaded.");
      return;
    }


    const rzp1 = new window.Razorpay(options);

    console.log("rzp1",rzp1)

    rzp1.on('payment.failed', (response) => {
      console.log('Failed Payment:---', response.error);
    });
    rzp1.open();
  } catch (error) {
    console.error('Error creating subscription', error);
  }
};
